//spacing
$header-container-max-width: 1440px;
$header-container-spacing-to-bottom-tablet: 10px;
$signin-info-spacing-left: 8px;
$heading-padding-top: 28px;
$heading-padding-bottom: 28px;
$header-height: 48px;
$header-mobile-height: 48px;
$header-icon-width: 40px;
$header-hamburger-icon-width: 21px;
$account-link-height: 35px;
$account-links-max-width: 167px;
$popover-menu-item-height: 48px;
$header-acc-info-desktop-margin-right: 26px;
$header-nav-icon-margin-right-desktop: 10px;
$header-logo-margin-right-desktop: 32px;
$header-logo-padding-mobile: 7px 0;
$header-cart-count-margin-bottom: 20px;
$header-cart-count-border-radius: 100px;
$header-cart-count-padding: 2px 6px 0 6px;
$header-search-margin-right-desktop: 40px;
$header-locator-margin-right-desktop: 26px;
$header-search-margin-right-tablet: 20px;
$header-container-topbar-account-info-margin-left: 0;
$header-container-topbar-account-info-signin-button-padding: 0;
$msv-profile-button-text-margin-left: 10.33px;
$msv-pop-over-box-shadow-color: rgba(0, 0, 0, 0.1);
$msv-account-pop-over-box-shadow-color: rgba(0, 0, 0, 0.13);

$header-modal-mobile-hamburger-content-padding: 0;
$header-modal-mobile-hamburger-body-padding: 0;
$header-modal-mobile-hamburger-body-button-padding: 12px 8px;
$header-modal-mobile-close-button-padding-right: 12px;
$header-modal-mobile-close-button-padding-top: 10px;

//style presets
:root {
    --msv-header-bg: var(--msv-accent-brand-color);
    --msv-header-font-color: var(--msv-font-secondary-color);
    --msv-header-icon-font-size: #{$msv-icon-size-xs};
}

.ms-header .msc-btn {
    background-color: var(--msv-header-bg);
}

.msc-modal .ms-header__mobile-hamburger .msc-btn {
    display: block;
    background-color: var(--msv-header-bg);
}

.lock-opaque {
    top: 0;
    position: fixed;
    z-index: 1010;
    width: 100%;
    box-shadow: $msv-depth8;

    .ms-header__collapsible-hamburger.show {
        /* stylelint-disable-next-line declaration-no-important -- Auto-suppressed. Please fix if possible. */
        min-height: auto !important;
        height: 80vh;
        overflow: auto;
    }
}

.ms-header {
    background-color: var(--msv-header-bg);
    color: var(--msv-header-font-color);

    margin-left: auto;
    margin-right: auto;
    .ms-header__logo {
        img {
            max-width: 200px;
            height: auto;
            @media (max-width: $msv-breakpoint-m) {
                max-width: 150px;
            }
            @media (max-width: 420px) {
                max-width: 125px;
            }
            @media (max-width: 400px) {
                max-width: 120px;
            }
        }
    }
    .ms-search {
        width: 400px ;
        max-width: 400px ;
        margin-right: 15px;
        border-radius: 30px;
      }

    &__container {
        margin: 0 auto;
        padding-bottom: 0;
        .ms-header__preferred-store-btn,
        .ms-search__icon,
        .ms-header__signin-button,
        .msc-wishlist-icon,
        .msc-cart-icon,
        .ms-header__profile-button {
            @include font-body-regular-m();

            color: var(--msv-header-font-color);
            border: 1px solid transparent;

            @include vfi();

            @media (max-width: $msv-breakpoint-l - 1) {
                height: 48px;
                width: 44px;
            }
        }

        .ms-header__account-info {
            @include font-body-regular-m();
            justify-content: flex-end;
            height: $header-height;
            margin-right: $header-acc-info-desktop-margin-right;
            display: flex;

            .ms-header__signin-button, .ms-header__profile-button {
                @include font-body-regular-m();

                background-color: var(--msv-header-bg);
                color: var(--msv-header-font-color);
                align-items: center;
                display: flex;
                height: 48px;
                white-space: nowrap;

                &::before {
                    @include msv-icon();
                    content: $msv-signin;
                    font-size: var(--msv-header-icon-font-size);
                    width: auto;
                    vertical-align: text-bottom;
                    text-align: center;
                    font-weight: normal;
                    color: #545554;
                }
            }

            &__account-link-container {
                height: $account-link-height;
            }

            a {
                @include font-body-regular-m();
                align-items: center;
                display: flex;
                height: $header-height;
                line-height: $msv-line-height-m;
                background-color: $msv-white;
                color: $msv-primary;
                white-space: nowrap;
                padding-left: 20px;
                padding-right: 20px;
            }

            .msc-popover {
                background-clip: padding-box;
                background-color: $msv-white;
                display: block;
                left: 0;
                max-width: $account-links-max-width;
                position: absolute;
                word-wrap: break-word;
                z-index: 1060;
                box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;

                .msc-popover-inner {
                    .ms-signin-info__account-link-button:hover, .ms-header__signout-button:hover {
                        background-color: $msv-white-20;
                    }
                }

                .msc-btn {
                    background-color: $msv-white;
                    color: $msv-primary;
                    font-weight: normal;
                    height: $popover-menu-item-height;
                }

                .msc-arrow {
                    display: none;
                }
            }

            .ms-profile-button-text, .ms-header__signin-button-text {
                margin-left: $msv-profile-button-text-margin-left;
                vertical-align: top;
                font-size: 14px;
                color: #545554;
            }
            .ms-header__profile-button {
                &.btn-link {
                    @media (max-width: $msv-breakpoint-m) {
                        padding-right: 0;
                        width: auto;
                    }
                }
            }
        }

        .ms-header__topbar {
            margin: 0 auto;
            max-width: $header-container-max-width;
            align-items: center;
            display: flex;
            flex-wrap: nowrap;
            height: $header-mobile-height;

            padding-left: $msv-layout-container-padding-m;
            padding-right: $msv-layout-container-padding-m;

            @media screen and (max-width: $msv-breakpoint-m) {
                padding-left: 5px;
                padding-right: 5px;
            }

            @media screen and (min-width: $msv-breakpoint-l) {
                padding-left: $msv-layout-container-padding-l;
                padding-right: $msv-layout-container-padding-l;
            }

            .ms-header__nav-icon {
                @include vfi();
                background: var(--msv-header-bg);
                display: flex;
                align-items: center;
                color: var(--msv-header-font-color);
                border: 1px solid transparent;
                margin-right: $header-nav-icon-margin-right-desktop;
                background-color: $msv-white;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-GlobalNavButton;
                    font-size: var(--msv-header-icon-font-size);
                    width: $header-hamburger-icon-width;
                    vertical-align: text-bottom;
                    text-align: center;
                    color: #545554;
                }

                @media (max-width: $msv-breakpoint-m - 1) {
                    height: 44px;
                    width: 44px;
                    margin-right: 0;
                }
                @media (max-width: 400px) {
                    width: auto;
                    padding-left: 0;
                }
            }

            .ms-header__logo {
                display: flex;
                flex: auto;
                margin-right: $header-logo-margin-right-desktop;

                a {
                    @include vfi();
                    display: block;
                }
            }

            .ms-header__mobile-logo {
                display: none;
            }

            .ms-header__preferred-store-container {
                .ms-header__preferred-store-btn {
                    border: 1px solid transparent;
                    display: flex;
                    height: $header-height;
                    line-height: $msv-line-height-m;
                    align-items: center;
                    padding: 0;

                    @include vfi();
                    @include add-icon($msv-Shop-Address);

                    &::before {
                        font-size: var(--msv-header-icon-font-size);
                        display: inline-block;
                        width: auto;
                        text-align: center;
                    }

                    .ms-header__preferred-store-text {
                        @include font-body-regular-m();
                        color: var(--msv-header-font-color);
                        white-space: nowrap;
                    }
                }
            }

            .ms-header__wishlist-desktop {
                align-items: center;
                height: 24px;
                width: 32px;
                padding: 0px;
                color: $nandor;
                background-color: transparent;
                display: block;

                @include add-icon($msv-Heart, before, 400);

                &::before {
                    font-size: var(--msv-header-icon-font-size);
                    width: 30px;
                    vertical-align: text-bottom;
                    text-align: center;
                }

                .msc-wishlist-icon__text {
                    display: none;
                }
            }

            .msc-cart-icon {
                display: flex;
                align-items: center;
                background-color: #fff;
                border: none;
                color: #545554;

                &:hover {
                    text-decoration: none;
                }

                &::before {
                    @include msv-icon();
                    content: $msv-shopping-bag;
                    font-size: var(--msv-header-icon-font-size);
                    vertical-align: text-bottom;
                    text-align: center;
                }

                &__count {
                    @include font-content-s();
                    color: var(--msv-header-font-color);
                    margin-bottom: $header-cart-count-margin-bottom;
                    padding: $header-cart-count-padding;
                    border-radius: $header-cart-count-border-radius;
                    text-align: center;
                    font-size: 9px ;
                    background-color: $california ;
                    font-weight: 500 ;
                    text-align: center ;
                    letter-spacing: normal ;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .ms-cart-icon-wrapper {
                border: 1px solid transparent;
                padding: 0;

                @include vfi();
            }

            .ms-header__account-info a:hover {
                text-decoration: none;
            }
        }

        .ms-header__collapsible-hamburger {
            position: absolute;
            width: 100%;
            left: 0;
            display: block;
            top: 93px;

            @media (max-width: $msv-breakpoint-l) {
                top: 68px;
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }

    /* stylelint-disable no-descending-specificity -- Suppressed. */
    &.hide-header-options {
        .ms-header__nav-icon,
        .msc-cart-icon,
        .msc-wishlist-icon,
        .ms-header__account-info {
            display: none;
        }
    }

    @media (min-width: $msv-breakpoint-xs) {
        .msc-cart-icon {
            &::before {
                width: $header-icon-width / 2;
            }
        }
    }

    @media screen and (min-width: $msv-breakpoint-l) {
        .ms-header__preferred-store-container {
            .ms-header__preferred-store-btn {
                &::before {
                    margin-right: $header-nav-icon-margin-right-desktop;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
        .mobile-navicon-container {
            display: none;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        &__container {
            .ms-header__topbar {
                .ms-search {
                    margin-right: $header-search-margin-right-tablet;
                    height: $header-height;
                }

                .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                }

                .ms-header__preferred-store-container {
                    margin-right: 0;
                }

                .ms-header__preferred-store-btn {
                    margin-right: $header-search-margin-right-tablet;

                    &::before {
                        margin-right: 0;
                    }
                }

                .ms-header__container .ms-header__topbar .ms-header__account-info {
                    margin-right: $header-search-margin-right-tablet;
                    margin-top: 0;
                }
            }
        }
    }

    @media (min-width: $msv-breakpoint-l) {
        padding-top: $heading-padding-top;
        padding-bottom: $heading-padding-bottom;

        &__modal {
            display: none;
        }

        &__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
                font-weight: normal;
            }

            &:focus {
                outline: none;
                border: none;
            }
        }

        &__divider {
            display: none;
        }

        .ms-search {
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: none;
            }
        }

        &__preferred-store-container {
            margin-right: $header-locator-margin-right-desktop;
        }

        .ms-header__wishlist-desktop {
            display: flex;
        }

        .ms-nav.desktop-vp {
            display: flex;
            position: relative;
            border-top: 1px solid var(--msv-header-font-color);
        }

        .mobile-navicon-container {
            display: none;
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        .ms-profile-button-text {
            display: none;
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                @include msv-icon();
                content: $msv-signin;
                color: var(--msv-header-font-color);
            }
        }
    }

    @media (max-width: $msv-breakpoint-l) {
        padding-top: $header-container-spacing-to-bottom-tablet;
        padding-bottom: $header-container-spacing-to-bottom-tablet;

        &__container {
            .ms-header__topbar {
                .ms-header__mobile-logo {
                    display: block;

                    a {
                        @include vfi();
                        padding: $header-logo-padding-mobile;
                    }
                }

                .ms-header__logo {
                   margin-right: 0;
                }

                .ms-header__preferred-store-container {
                    .ms-header__preferred-store-btn {
                        @include vfi();

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                            vertical-align: text-bottom;
                            text-align: center;
                        }

                        .ms-header__preferred-store-text {
                            display: none;
                        }
                    }
                }

                .ms-header__account-info {
                    margin-left: $header-container-topbar-account-info-margin-left;

                    .ms-header__signin-button {
                        padding: $header-container-topbar-account-info-signin-button-padding;

                        .ms-header__signin-button-text {
                            display: none;
                        }
                    }
                }
            }
        }

        .ms-search {
            display: flex;
            justify-content: flex-end;
            flex-basis: 0;
            flex-grow: 1;
            margin-right: $header-search-margin-right-tablet;
            border: none;

            .hide {
                display: none;
            }

            .ms-search__label.bx-show {
                display: block;

                .ms-search__icon {
                    align-items: center;
                    color: var(--msv-header-font-color);
                    font-weight: normal;

                    @media (max-width: $msv-breakpoint-l - 1) {
                        padding: 0;

                        &::before {
                            font-size: var(--msv-header-icon-font-size);
                        }
                    }

                    .ms-search__icon-text {
                        display: none;
                    }
                }
            }
        }

        .ms-profile-button-text {
            @include visually-hidden();
        }

        .ms-header__profile-button {
            color: var(--msv-header-font-color);
            font-weight: normal;
            background: var(--msv-header-bg);

            &::before {
                @include font-body-regular-m();
                color: var(--msv-header-font-color);
                width: $header-icon-width;

                @include msv-icon();
                content: $msv-signin;
            }
        }
    }

    @media (max-width: ($msv-breakpoint-m)-1) {
        &__container {
            .ms-header__topbar {
                .desktop-navicon-container {
                    display: none;
                }

                .ms-search {
                    margin-right: auto;
                }

                .ms-header__account-info {
                    margin-right: 0;
                    background-color: $msv-white;
                }

                .ms-header__preferred-store-btn {
                    margin-right: 0;

                    &::before {
                        margin-right: 0;
                    }
                }
            }
        }
    }
    .header-wishlist {
        position: relative;
        padding-right: 6px;
        .msc-cart-icon__count {
            position: absolute;
            top: -9px;
            right: -3px;
        }
    }
}

.ms-header__modal {
    @media (min-width: ($msv-breakpoint-m)) {
        display: none;
    }

    .msc-modal {
        .ms-header__mobile-hamburger {
            background: var(--msv-header-bg);
            height: 100%;
            margin: 0;
            max-width: none;

            .msc-modal__content {
                height: 100%;
                padding: $header-modal-mobile-hamburger-content-padding;
                background: var(--msv-header-bg);
                color: var(--msv-header-font-color);

                .ms-header__mobile-hamburger-menu-body {
                    padding: $header-modal-mobile-hamburger-body-padding;

                    .msc-btn {
                        font-size: 16px;
                        height: unset;
                        line-height: 22px;
                        padding: $header-modal-mobile-hamburger-body-button-padding;
                    }
                }

                .msc-modal__header {
                    display: block;
                    height: 18px;

                    .msc-modal__close-button {
                        color: var(--msv-header-font-color);
                        right: 0;
                        margin: 0;
                        opacity: 1;
                        padding-right: $header-modal-mobile-close-button-padding-right;
                        padding-top: $header-modal-mobile-close-button-padding-top;
                        z-index: 1000;

                        &:focus {
                            outline-offset: 0;
                        }

                        &::before {
                            @include msv-icon();
                            content: $msv-Cancel;
                            font-size: var(--msv-header-icon-font-size);
                            position: unset;
                            text-align: center;
                            vertical-align: text-bottom;
                        }

                        span {
                            display: none;
                        }
                    }
                }
            }

            .ms-header__mobile-hamburger-menu-links {
                display: none;
            }
        }
    }
}

.skip-to-main {
    @include font-body-regular-s();
    color: $msv-black;
}

.sudwoll-header {
    max-width: 1440px;
    height: 150px;
    padding-bottom: 10px;
    @media (max-width: $msv-breakpoint-l) {
        max-height: 125px;
    }
    .ms-nav__list__item__link,
    .ms-nav__list__item__button {
        background-color: $msv-white !important;
        font-size: 14px !important;
        text-transform: uppercase !important;
        font-weight: 700 !important;
        letter-spacing: 1px !important;
        color: $nandor !important;
        align-items: center !important;
    }
    .ms-nav__list {
        align-items: center;
    }
  }
.ms-nav__list__item__link {
    margin-bottom: 0px !important;
    align-items: center;
    position: relative;
    span {
        white-space: nowrap;
        letter-spacing: 0;
        @media (max-width: 767px) {
            font-size: 14px;
            white-space: normal;
        }
    }
    &:after {
        position: absolute;
        right: 10px;
    }
    img {
        width: 60px;
        height: auto;
        margin-right: 5px;
    }
}

.sudwoll-header,
.sudwoll-header .ms-nav > .ms-nav__list,
.sudwoll-header .ms-nav > .ms-nav__list > .ms-nav__list__item,
.sudwoll-header .ms-nav,
header {
  background-color: #fff !important;
  border: none !important;
  margin-right: 5px !important;
  margin-top: 0px !important;
}
.sudwoll-header {
    margin: 0 auto !important;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.04);
}
