.error-page {
    text-align: center;
    h2 {
        font-size: 48px;
        @media (max-width: $msv-breakpoint-m) {
            font-size: 32px;
        }
    }
    p {
        font-size: 20px;
    }
    .back-to-home {
        a {
            @include primary-btn();
            text-decoration: none;
            line-height: 44px;
        }
    }
}