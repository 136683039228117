
//Variables
$site-picker-desktop-list-bg-color: #F3F3F3;
$site-picker-list-item-img-padding: 10px 10px 1px 0;
$site-picker-list-margin: 5px;
$site-picker-list-item-padding-top: 10px;
$site-picker-list-item-padding-bottom: 10px;
$site-picker-list-item-padding-left: 10px;
$site-picker-list-item-padding-right: 10px;
$site-picker-line-height: 40px;
$site-picker-height: 48px;
$site-picker-transparent: transparent;

//style presets
:root {
    --ms-site-picker-font-size: var(--msv-body-font-size-m);
    --ms-site-picker-gray-500: #{$msv-gray-500};
    --ms-site-picker-bg-color: #{$site-picker-transparent};
    --ms-site-picker-desktop-list-bg-color: #{$site-picker-desktop-list-bg-color};
    --ms-site-picker-mobile-list-bg-color: #{$msv-white};
    --ms-site-picker-font-color: #{$msv-black};
}

.ms-site-picker {
    z-index: 1015;
    align-self: flex-start;
    align-items: self-end;
    font-size: var(--ms-site-picker-font-size);
    height: $site-picker-height;

    &__button {
        @include font-body-regular-m();
        background-color: var(--ms-site-picker-bg-color);
        border: none;
        cursor: pointer;
        height: 100%;
        width: 100%;
        color: $nandor;
        display: flex;
        align-items: center;
        font-size: 14px;
        @media (max-width: $msv-breakpoint-m) {
            padding: 0;
        }
        &::before {
            @include msv-icon();
            content: "\EDCF";
            margin-right: 4px;
            line-height: $site-picker-line-height;
            vertical-align: middle;
            font-size: $msv-icon-size-xs;
            color: $nandor;
        }

        &:focus {
            border: 1px dashed var(--ms-site-picker-gray-500);
            outline: none;
        }
    }

    &__span {
        &::after {
            @include msv-icon();
            content: $msv-ChevronDown;
            line-height: $site-picker-line-height;
            vertical-align: middle;
            font-size: $msv-icon-size-xs;
            color: $nandor;
            @media (max-width: $msv-breakpoint-m) {
                display: none;
            }
        }
    }

    &__list {
        box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;
        background: $msv-white;
        display: block;
        list-style-type: none;
        padding-left: 0;
        margin: $site-picker-list-margin;
        width: 100%;
        position: absolute;
        width: auto;
        right: 60px;
        @media (max-width: $msv-breakpoint-l) {
            right: 32px;
        }
        @media (max-width: $msv-breakpoint-m) {
            right: 8px;
        }

        &__item {
            cursor: pointer;
            padding: 4px 0;

            &__link {
                color: var(--ms-site-picker-font-color);
                flex-direction: row-reverse;
                justify-content: flex-end;
                padding-left: $site-picker-list-item-padding-left;
                padding-right: $site-picker-list-item-padding-right;
                align-items: flex-end;
                width: 100%;
                font-size: 14px;
                line-height: 1;
                height: 25px;
                overflow: hidden;

                &:focus {
                    border: 1px dashed var(--ms-site-picker-gray-500);
                    outline: none;
                }
            }

            &__img {
                padding: $site-picker-list-item-img-padding;
                width: 35px;
                height: auto;
            }
        }
    }
}

// @media (max-width: $msv-breakpoint-l) {
//     .ms-site-picker {
//         margin-left: 0;
//         height: auto;
//     }

//     .ms-site-picker__drawer {
//         .drawer__button {
//             background-color: var(--ms-site-picker-bg-color);
//             border: none;
//             color: var(--ms-site-picker-font-color);
//             cursor: pointer;
//             width: 100%;
//             font-size: $msv-font-size-m;
//             padding-left: 20px;
//             padding-right: $site-picker-list-item-padding-right;
//             height: $site-picker-height;
//             outline-offset: -1px;

//             .drawer__buttontext {
//                 float: left;
//             }

//             .ms-site-picker__drawer-close {
//                 position: absolute;
//                 right: 10px;

//                 &::after {
//                     @include msv-icon();
//                     color: var(--msv-header-font-color);
//                     content: $msv-ChevronDown;
//                 }
//             }

//             .ms-site-picker__drawer-open {
//                 position: absolute;
//                 right: 10px;

//                 &::after {
//                     @include msv-icon();
//                     color: var(--msv-header-font-color);
//                     content: $msv-ChevronUp;
//                 }
//             }
//         }

//         .ms-site-picker__list__item__link {
//             flex-direction: row-reverse;
//             justify-content: flex-end;
//             background-color: var(--ms-site-picker-mobile-list-bg-color);
//             color: var(--ms-site-picker-font-color);
//             height: $site-picker-height;
//             font-size: $msv-font-size-m;
//             padding-bottom: $site-picker-list-item-padding-bottom;
//             padding-left: 20px;
//             padding-right: $site-picker-list-item-padding-right;
//             align-items: flex-end;
//             width: 100%;

//             &:focus {
//                 border: 1px dashed var(--ms-site-picker-gray-500);
//                 outline: none;
//             }

//             .ms-site-picker__list__item__text {
//                 margin: auto 0;
//             }
//         }
//     }
// }
