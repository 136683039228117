.cpl-area1 {
    padding: 0px !important;
}
.cpl-content-area1 {
    box-shadow: 0px 10px 20px 0px $grey-light;
    padding: 50px 10px 10px 10px;
    background-color: $msv-white;
    margin: 0 70px 0 70px;
    @media (min-width: 1138px) and (max-width: 1200px) {
        margin: 0px !important;
    }
    @media (min-width: 1024px) and (max-width: 1025px) {
        margin: 0px !important;
    }
    @media (min-width: 767px) and (max-width: 992px) { 
        margin: 0px !important;
    }
    @media (max-width: 768px) {
        margin: 0px !important;
        padding-top: 0px !important;
    }
     
    .lazyloading {
        display: none !important;
    }
    .ms-content-block__details {
        position: unset !important;
        @media (width: 820px) {
            background: transparent !important;       
        }
        @media (min-width: 767px) and (max-width: 992px) { 
            background-color: transparent !important;
        }

        @media (max-width: 768px) {
            background-color: transparent !important;
        }
    }
    img {
        padding: 15px; 
        @media screen and (max-width: 767px) { 
            height: auto;
        }
    }
    p,
    .ms-content-block__text {
        color: $msv-black !important;
        line-height: normal !important;
        width: 100% !important;
    }
    h2 {
        color: $california !important;
        font-size: 32px !important;
        font-weight: normal !important;
        line-height: normal !important;
        width: 100% !important;
        font-weight: 700 !important;
        letter-spacing: -1px !important;
        padding-bottom: 20px !important;
    }
    h6 {
        a {
            font-size: 14px;
            color: $dusty-gray !important;
        }
    }
    .ms-content-block__text {
        ul {
             padding-top: 15px !important;
       }
    }
   
}

.category-container {
    display: inline-block;
    width: calc(33.3% - 20px);
    background-color: $msv-white;
    margin: 10px;
    border: solid 1px $silver;
    text-align: left;
    @media (max-width: 768px) {
        width: 47.3%;
    }
    @media screen and (max-width: 767px) { 
        width: calc(100% - 20px);
    }
    @media (min-width: 800px) and (max-width: 900px) {
        width: 47%;
    }
    @media (min-width: 767px) and (max-width: 992px) { 
        width: 47%;
    }
    @media (min-width: 1024px) and (max-width: 1025px) {
        width: 31.3%;
    }
    
    @media (min-width: 1138px) and (max-width: 1200px) {
        width: 31.6%;
    }
    @media (min-width: 1000px) and (max-width: 1200px) {
        width: 31% ;
    }
    @media (width: 820px) {
        width: 379px;
    }
    &:hover {
      box-shadow: 6px 6px 10px 0px $silver;
      transition: all 580ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    img {
      width: 405px;
      height: 225px;
      @media screen and (max-width: 767px) { 
        width: auto;
        height: 225px;
      }
    }
}
.sub-category-header {
    margin-top: 30px !important;
    background-color: $msv-white;
    width: 1300px;
    margin: auto;
    box-shadow: 0px 0px 20px 0px $grey-light;
    border: solid $msv-white 20px;
    margin-bottom: 25px !important;
    img {
      width: 250px;
      margin: 0 auto;
  }
}
.sud-nav .ms-nav__list {
    background-color: transparent !important;
}
.sub-category-wrap {
    width: 1300px;
    margin: auto;
    box-shadow: 0px 0px 20px 0px $grey-light;
    margin-top: 20px;
    padding: 10px;
    background-color: $msv-white;
}

.msc-subcategory {
    display: block;
    &:hover {
        text-decoration: none !important;
    }
    &__details {
        padding: 20px;
        h4 {
            font-size: 20px;
        }
    }
}

