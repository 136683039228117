// homepage main banner
.sudwoll-carousel1 {
    h1 {
        color: $nandor !important;
        font-size: 150px !important;
        font-weight: 700 !important;
        letter-spacing: -5px !important;
        line-height: normal !important;

        // text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
        @media screen and (max-width: 768px) {
            font-size: 42px !important;
            letter-spacing: -3px !important;
        }
    }

    .ms-content-block__cta {
        @media screen and (max-width: 767px) {
            margin: 10px 0px 15px 0px !important;
        }
    }

    .ms-content-block__details {
        z-index: 3;

        @media screen and (min-width: $msv-breakpoint-m) {
            position: absolute !important;
            top: 0;
            left: 0;
            right: 0;
            height: 100%;
        }
    }

    .ms-content-block__text {
        p {
            color: $nandor;
            // text-shadow: 0 2px 6px rgba(0, 0, 0, 0.5) !important;
            width: 360px;
            margin: 0 auto;
        }
    }

    .msc-cta__primary {
        background-color: $nandor !important;
        border-radius: 50px !important;
        color: $msv-white !important;
        line-height: normal !important;
        text-decoration: none;
        border: none !important;
        font-size: 14px !important;
        text-transform: uppercase !important;

        &:hover {
            color: $msv-white !important;
            background-color: $california !important;
            font-size: 14px !important;
        }
    }
}

//homepage video banner styling 

.video-txt-area {
    z-index: 10 !important;
    position: absolute !important;
    padding: 5px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, 75%) !important;

    @media (min-width: 1201px) and (max-width: 1378px) {
        left: 38% !important;
        transform: translate(-25%, 75%) !important;
    }

    @media (min-width: 993px) and (max-width: 1024px) {
        left: 25% !important;
        transform: translate(-15%, 50%) !important;
    }

    @media (min-width: 1025px) and (max-width: 1200px) {
        left: 25% !important;
        transform: translate(-15%, 50%) !important;
    }

    @media (min-width: 1024px) and (max-width: 1025px) {
        left: 30% !important;
        transform: translate(-20%, 50%) !important;
    }

    @media (min-width: 767px) and (max-width: 992px) {
        left: 25% !important;
        transform: translate(-15%, 25%) !important;
    }

    @media (max-width: 768px) {
        left: 25% !important;
        transform: translate(-15%, 25%) !important;
    }

    @media screen and (max-width: 767px) {
        left: 50% !important;
        transform: translateX(-50%) !important;
        width: 100%;
    }

    .msc-cta__primary {
        background-color: $california !important;
        border-radius: 50px;
        color: $msv-white !important;
        border: none !important;
        font-size: 14px !important;
        text-transform: uppercase !important;
        padding: 10px 50px;
        margin-top: 25px;
    }

    .msc-cta__primary:hover {
        background-color: $nandor !important;
        text-decoration: none;
    }
}

.video-area {
    margin-top: 50px;

    .ms-video-player {
        padding: 0 20px;

        @media(max-width: $msv-breakpoint-m) {
            padding: 0;
        }
    }

    .ms-video-player__details {
        height: auto;
        width: calc(100% - 40px);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        margin: 0;

        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
            background: transparent;
        }

        .ms-video-player__details__text {
            color: $msv-white;
            text-align: center;

            @media(max-width: $msv-breakpoint-m) {
                font-size: 14px;
                margin-top: 0;
            }
        }

        .ms-video-player__details__cta__link {
            background-color: $california;
            border-color: $california;
            border-radius: 50px;
            color: $msv-white;
            border: none;
            font-size: 14px;
            text-transform: uppercase;

            @media(max-width: $msv-breakpoint-m) {
                margin-top: 16px;
            }

            &:hover {
                text-decoration: none;
                background-color: $nandor;
                border-color: $nandor;
            }
        }

        .ms-video-player__details__cta__link:hover {
            text-decoration: none;
            background-color: $nandor;
            border-color: $nandor;
        }
    }
}


// new Homepage Components 

.homepage-collection {
    background-color: $msv-white;

    .ms-product-collection__heading {
        font-weight: 400;
        font-size: 32px;
        margin-top: 80px;
        margin-bottom: 35px;

        @media (max-width: $msv-breakpoint-m) {
            margin-top: 48px;
            margin-bottom: 24px;
        }
    }

    .msc-product {
        display: flex;
        flex-direction: column;
    }

    .ms-product-collection {
        .msc-product {
            .msc-product__title {
                margin-top: 10px;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .msc-product__composition,
        .msc-product__spinning-tech,
        .msc-product__treatment,
        .msc-product__dimensions,
        .msc-product__unit-of-measure {
            display: none;
        }

        .msc-empty_image {
            display: block !important;
        }

        .msc-product__details {
            float: none !important;
            padding: 0 !important;
        }

        .msc-price__actual {
            margin: 0;
        }

        .msc-ss-carousel {
            .msc-flipper {
                top: 50%;
                transform: translateY(-50%);
                left: -35px;
                width: 40px;
                height: 40px;
                background: transparent;

                @media (max-width: $msv-breakpoint-m) {
                    left: -15px;
                }

                &:hover {
                    background: transparent;
                    box-shadow: none;
                }

                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }

                &.msc-ss-carousel__flipper--next {
                    left: unset;
                    right: -35px;

                    @media (max-width: $msv-breakpoint-m) {
                        left: unset;
                        right: -15px;
                    }
                }

                .msi-chevron-right,
                .msi-chevron-left {
                    &::before {
                        font-size: 32px;
                    }
                }
            }
        }
    }

    &.container {
        max-width: 100%;
    }
}

// image with content on left and vice versa  

.image-copy {
    background: $msv-white;

    .ms-content-block {
        position: relative;
        z-index: 3;
        display: flex;

        @media (max-width: $msv-breakpoint-m) {
            flex-direction: column;
        }

        .ms-content-block__image {
            width: 50%;

            img {
                height: auto;
            }

            @media(max-width: $msv-breakpoint-m) {
                width: 100%;
            }
        }

        .ms-content-block__details {
            width: 50%;
            padding: 50px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;

            @media (max-width: $msv-breakpoint-m) {
                padding: 32px 16px 50px;
                margin: 0;
                width: 100%;
            }
        }

        .ms-content-block__title {
            line-height: 1.2;
            font-weight: 300;
            font-size: 36px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 28px;
                margin-bottom: 16px;
            }
        }

        .ms-content-block__text {
            margin-top: 16px;
            margin-right: 20px;

            @media (max-width: $msv-breakpoint-m) {
                margin: 0;
                margin-bottom: 32px;
            }
        }

        .ms-content-block__cta {
            margin-top: 36px;
            margin-right: 20px;

            @media (max-width: $msv-breakpoint-m) {
                margin: 0;
            }

            .msc-cta__primary {
                @include primary-btn();
                white-space: nowrap;
                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                }
            }
        }

        &[data-m-layout="left-right"] {
            .ms-content-block__image {
                width: auto;

                img {
                    height: auto;
                }

                @media(min-width: $msv-breakpoint-m) {
                    overflow: hidden;
                }
            }

            .ms-content-block__details {
                width: auto;

                @media (max-width: $msv-breakpoint-m) {
                    padding: 32px 16px 50px;
                    margin: 0;
                }
            }

            .ms-content-block__title {
                line-height: 1.2;
                font-weight: 300;
                font-size: 36px;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 28px;
                }
            }

            .ms-content-block__text {
                margin-top: 16px;
                margin-left: 0;
                margin-right: 0;
            }

            .ms-content-block__cta {
                margin-top: 36px;
                margin-left: 0;
                margin-right: 0;
            }
        }
        &.textplacement__left {
            flex-direction: row-reverse;

            @media (max-width: $msv-breakpoint-m) {
                flex-direction: column;
            }
        }
    }
}

// main banner override with carousel 1

.main-banner {
    @media (max-width: $msv-breakpoint-l) {
        margin-bottom: 20px;
    }

    .scroll-btn {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 90px;
        position: absolute;
        right: 50%;
        z-index: 1;
        bottom: 38px;
        border: 0;
        background: transparent;
        transform: translateX(50%);
        padding: 0;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 1px;
        font-weight: 400;
        color: $msv-gray-20;

        @media (max-width: $msv-breakpoint-l) {
            display: none;
        }

        &::before {
            content: "";
            display: inline-block;
            // background-image: url("data:image/svg+xml,%3Csvg width='24' height='33' viewBox='0 0 24 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.83 0L12 9.17L21.17 0L24 2.83L12 14.83L0 2.83L2.83 0Z' fill='%23FF9708' fill-opacity='0.25'/%3E%3Cpath d='M2.83 9L12 18.17L21.17 9L24 11.83L12 23.83L0 11.83L2.83 9Z' fill='%23FF9708' fill-opacity='0.5'/%3E%3Cpath d='M2.83 18L12 27.17L21.17 18L24 20.83L12 32.83L0 20.83L2.83 18Z' fill='%23FF9708'/%3E%3C/svg%3E%0A");
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='33' viewBox='0 0 24 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.83 0L12 9.17L21.17 0L24 2.83L12 14.83L0 2.83L2.83 0Z' fill='%235f5f5e' fill-opacity='0.25'/%3E%3Cpath d='M2.83 9L12 18.17L21.17 9L24 11.83L12 23.83L0 11.83L2.83 9Z' fill='%235f5f5e' fill-opacity='0.5'/%3E%3Cpath d='M2.83 18L12 27.17L21.17 18L24 20.83L12 32.83L0 20.83L2.83 18Z' fill='%235f5f5e'/%3E%3C/svg%3E%0A");
            background-size: cover;
            animation: bounce 0.6s;
            animation-direction: alternate;
            animation-iteration-count: infinite;
            filter: brightness(0.5);
            font-size: 35px;
            color: $california;
            height: 55px;
            width: 40px;
            position: absolute;
            top: 0;
        }
    }

    .ms-content-block {
        .ms-content-block__image {
            @media (min-width: $msv-breakpoint-m) {
                height: calc(100vh - 150px);
                min-height: 500px;
            }

            img {
                height: auto;

                @media (min-width: $msv-breakpoint-m) {
                    height: 100%;
                }
            }
        }

        .ms-content-block__title {
            font-size: 70px !important;
            letter-spacing: 2px !important;
            font-weight: 600 !important;
            margin-bottom: 16px;

            @media (max-width: $msv-breakpoint-m) {
                font-size: 32px !important;
            }
        }

        .ms-content-block__details {
            // background: rgba($black ,0.1);
            margin: 0;
            align-items: center;
            display: flex;
            justify-content: center;

            @media (max-width: $msv-breakpoint-l) {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent !important;
            }

        }

        &[data-m-layout="full-width"] {
            .ms-content-block__details {
                @media (max-width: $msv-breakpoint-l) {
                    background-color: transparent !important;
                }
            }
        }

        .ms-content-block__text {
            p {
                font-weight: 600;
                font-size: 20px;
                letter-spacing: 2px;

                @media (max-width: $msv-breakpoint-m) {
                    font-size: 16px !important;
                    margin-bottom: 0;
                    line-height: 1;
                }
            }
        }
    }
}

.secondary-banner {
    max-width: 100% !important;
    padding-top: 64px;
    padding-bottom: 48px;
    background-color: $white;
    z-index: 3;
    position: relative;

    @media(max-width: $msv-breakpoint-m) {
        padding-top: 0;
    }

    .ms-content-block {
        .ms-content-block__details {
            @media(min-width: $msv-breakpoint-l) {
                margin: 0;
                justify-content: center;
                width: 45%;
                margin-left: auto;
            }

            .msc-cta__primary {
                @include primary-btn();
            }
        }

        .ms-content-block__title {
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 20px;
            width: 100%;
            padding-right: 16px;
            line-height: 1.2;

            @media (max-width: $msv-breakpoint-l) {
                color: $msv-gray-60;
                font-size: 32px;
            }
        }

        .ms-content-block__text {
            margin-top: 0;
            width: 100%;
            padding-right: 16px;

            p {
                font-size: 16px;
                font-weight: 500;
                margin: 0 0 20px;

                @media (max-width: $msv-breakpoint-l) {
                    color: $msv-gray-60;
                }
            }
        }

        .ms-content-block__cta {
            margin-top: 0;
        }

        .ms-content-block__image {
            img {
                height: auto;
            }
        }
    }
}

// info section content at the bottom
.company-info {
    background: $concrete;
    position: relative;
    z-index: 3;

    .ms-text-block {
        max-width: 900px;
        margin: 0 auto;
        padding: 36px 16px 36px;

        h2 {
            margin-bottom: 10px;
            font-size: 16px;
            font-weight: 400;
        }

        p {
            font-size: 12px;
        }
    }
}

body {
    &.b2bhome {
        #main {
            background-color: $msv-white !important;
            padding: 0;
        }

        footer {
            background: $msv-white;
            position: relative;
            z-index: 3;
        }

        &::before {
            content: "";
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0, 0, 1000, 1000' width='100%25' height='100%25' preserveAspectRatio='none'%3E%3Cpath d='M0,500 Q500,0 1000,900' stroke='%23FF9708' stroke-width='2' opacity='1' fill='none' id='yarn-svg' style='visibility: visible;stroke-dashoffset: 1e-05;stroke-dasharray: none;stroke: rgb(255, 151, 8);'%3E%3C/path%3E%3C/svg%3E");
            position: fixed;
            background-repeat: no-repeat;
            top: 90px;
            height: 100%;
            width: 100%;
            z-index: 2;
            animation-name: animate-width;
            -webkit-animation-name: animate-width;
            animation-duration: 2s;
            animation-direction: alternate;
            display: none;

            @media (max-width: $msv-breakpoint-l) {
                display: none;
            }
        }
    }
}

// animation
@keyframes bounce {
    from {
        transform: translate3d(0, 0, 0);
    }

    to {
        transform: translate3d(0, 10px, 0);
    }
}

@keyframes animate-width {
    from {
        background-position: -1320px;
    }

    to {
        background-position: 0px;
    }
}

.scroll-btn {
    display: none;
}

// PDP collection
.pdp-collection {
    .row {
        background-color: $msv-white;
    }

    .ms-product-collection__heading {
        font-weight: 400;
        font-size: 32px;
        margin-top: 24px;
        margin-bottom: 24px;

        @media (max-width: $msv-breakpoint-m) {
            margin-top: 32px;
            margin-bottom: 0;
        }
    }

    .msc-product {
        display: flex;
        flex-direction: column;
    }

    .ms-product-collection {
        padding-bottom: 24px;

        .msc-product {
            .msc-product__title {
                margin-top: 10px;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .msc-ss-carousel {
            @media (max-width: $msv-breakpoint-m) {
                padding-top: 0;
            }
        }

        .msc-product__description {
            display: block;
            white-space: normal;
            font-weight: 600;
        }

        .msc-product__title,
        .msc-price,
        .msc-product__dimensions,
        .msc-product__unit-of-measure,
        .msc-product__nominal-count,
        .msc-product__variance {
            display: none;
        }

        .msc-empty_image {
            display: block !important;
        }

        .msc-product__details {
            float: none !important;
            padding: 0 !important;
        }

        .msc-price__actual {
            margin: 0;
        }

        .msc-ss-carousel {
            .msc-flipper {
                transform: translateY(-50%);
                width: 40px;
                height: 40px;
                background: transparent;

                &:hover {
                    background: transparent;
                    box-shadow: none;
                }

                &:focus {
                    outline: none;
                    border: none;
                    box-shadow: none;
                }

                .msi-chevron-right,
                .msi-chevron-left {
                    &::before {
                        font-size: 32px;
                    }
                }
            }
        }
    }
}