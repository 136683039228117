$msv-checkout-rtl-radio-margin-left: 20px;
$msv-checkout-delivery-options-error-title-icon-margin-left: 22px;
$msv-checkout-delivery-options-error-message-padding-right: 20px;
$msv-checkout-delivery-options-error-message-padding-left: 64px;

[dir="rtl"] {
    .ms-checkout-delivery-options {
        &__input-radio {
            margin-left: $msv-checkout-rtl-radio-margin-left;
        }

        &__option {
            @media screen and (max-width: $msv-breakpoint-m) {
                margin-left: 20px;
                margin-right: 0;
            }
        }

        &__error-title {
            &::before {
                margin-right: 0;
                margin-left: $msv-checkout-delivery-options-error-title-icon-margin-left;
            }
        }

        &__error-message {
            padding-right: $msv-checkout-delivery-options-error-message-padding-right;
            padding-left: $msv-checkout-delivery-options-error-message-padding-left;
        }
    }
}
.ms-checkout {
    &__guided-card {
        .ms-checkout-section-container {
            @include font-heading-h5-l();
                font-size: 16px;
                font-weight: 400;
                margin-bottom: 12px;
                border: 1px solid $alto;

                & > div {
                    border-bottom: 1px solid $alto;
                    padding: 16px;

                    &:last-child {
                        border: 0;
                    }
                }
            // .ms-checkout-payment-instrument {
            //     &-paypal {
            //         iframe {
            //             height: 60px !important;
            //             margin-top: 14px !important;
            //         }
            //     }
            //     &-visa {
            //         border: 1px solid #ff00ff;
            //     }
            // }
        }
    }
}

.ms-checkout-customer-account {
    &__creditBlock {
        border-top: 1px solid #c4c4c4;
        border-bottom: 1px solid #c4c4c4;
        margin: -15px 0 15px;
        padding: 15px 0 22px;
        .ms-checkout-credit__label {
            font-size: 16px;
            font-weight: 400;
            margin-bottom: 8px;
        }
        .ms-checkout-credit__input-amount {
            font-family: "Rubik-Regular";
            font-weight: 400;
            font-style: normal;
            font-size: 14px;
            line-height: 20px;
            background-color: #e9ecef;
            opacity: 1;
            border: 0.5px solid #c4c4c4;
            border-radius: 3px;
            box-sizing: border-box;
            display: block;
            height: 52px;
            outline: none;
            padding: 16px;
            width: 100%;
            width: 44%;
            display: inline-block;
            margin-bottom: 8px;
        }
    }
}
.success-payment {
    background-color: #d6ffb8;
    border-radius: 3px;
    color: #4a9440;
    margin-top: 15px;
    padding: 20px;
}
.mt-15 {
    margin-top: 15px;
}