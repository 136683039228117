.msc-order-summary-wrapper,
.ms-checkout__side-control-first,
.ms-checkout__line-items {
  background-color: $msv-white !important;
  box-shadow: 0px 0px 10px 0px $concrete !important;
  padding: 10px !important;
  border: none !important;
  border-radius: 0px !important;
}
.ms-cart .msc-order-summary__items, .ms-cart .msc-order-summary__checkout{ border: none !important;}
.ms-account-management-address {
    width: 100% !important;
    margin-bottom: 10px !important;
}
.ms-account-profile-wrapper {
    width: 100% !important;
}
.ms-account-profile {
    display: block !important;
}
.msc-order-summary__items .msc-order-summary__items {
    box-shadow: none !important;
    padding: 0px !important;
}
.ms-search__autoSuggest .ms-search__autoSuggest__category .msc-autoSuggest__categoryResults-items li a:hover {
    background-color: transparent !important;
}
.modal-open {
    right: 0px !important;
}
#main {
  background-color: $concrete !important;
  padding-bottom: 25px;
  @media screen and (max-width: 767px) {
    padding-bottom: 25px;
  }
}
.ms-header__container .ms-header__account-info {
    margin-right: 5px !important;
    @media (max-width: $msv-breakpoint-m) {
      margin-right: 0 !important;
      padding-right: 6px;
    }
}
.ms-nav__list {
    display: flex !important;
    justify-content: center !important;
}
.msc-carousel__indicators .active {
  background-color: $california !important;
}
.ms-search__form-cancelSearch {
    display: none !important;
}
.ms-search__form-submitSearch {
  color: $california !important;
  background-color: $msv-white !important;
  margin-left: 14px !important;
}
.ms-header__signin-button {
  background-color: $msv-white !important;
}
.ms-header__profile-button::before:hover {
    text-decoration: none !important;
}
.ms-header__profile-button:hover {
    text-decoration: none !important;
}
.ms-header__container .ms-header__topbar .ms-cart-icon-wrapper {
    border: none !important;
}
.ms-header .ms-header__account-info a {
    padding: 0 10px !important;
}
.nav-menu-close,
.ms-header__nav-icon {
    display: none !important;
}
.ms-search-result-container .ms-product-search-result__item .msc-product .msc_image {
    width: 50px !important;
    height: 50px !important;
}
.ms-nav > .ms-nav__list .ms-nav-close-button .nav-menu-close::before {
  color: $nandor !important;
}
.msc-product__image {
    float: left !important;
}

.msc-product__details {
    float: left !important;
    padding: 15px 0 0 15px !important;
}
.ms-product-search-result__item .msc-product__details{display:none !important}
.msc-add-to-order-template-icon {
    float: left !important;
    min-width: auto !important;
    margin-top: -5px !important;
}
.msc-add-to-cart-extra-actions {
    border: none !important;
    background-color: transparent !important;
    padding: 15px 0px 0px 10px !important;
    vertical-align: middle !important;
    @media (max-width: 575px) {
      padding: 15px 0px 0px 0 !important;
    }
}
.product_detail-container {
    width: 400px;
    float: right;
}
ul.msc-product__data-details li,
ul.fibre-blend li {
    padding-bottom: 10px;
    font-weight: 500;
    font-size: 12px;
}

ul.msc-product__data-details li,
.color-card {
    display: flex !important;
    flex-direction: row;
    align-items: center;
    span {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.msc-product__data ul li span {
    font-size: 12px;
}
.msc-product__fibre div {
    float: left;
    width: 50%;
    font-size: 12px;
}
.msc-product__fibre li,
.pdp-content-area1 .availability li {
    margin: 2px 0;
    font-size: 12px;
    font-weight: 500;
    color: $gray;
}
.msc-product__fibre span,
.fibre-blend span,
.pdp-content-area1 ul.msc-product__fibre li .twist-value {
  margin: 0px 5px 2px 0px;
  display: inline-block;
  width: auto;
  color: $msv-gray-20;
}
ul.msc-product__data-details li > span:first-child {
  text-transform: uppercase !important;
  color: $gray !important;
  width: 50%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  background-color: transparent !important;
  padding: 0px;
  max-width: 50%;
}
ul.msc-product__data-details li > span:last-child {
  width: 50%;
  max-width: 50%;
}

ul.fibre-blend li span:first-child {
  color: $gray !important;
  width: 50%;
  display: inline-block;
  font-size: 12px;
  text-align: left;
  background-color: transparent !important;
  padding: 0px;
  margin: 0px;
}

.msc-swatch-container__item__image.msc-swatch-container__item__selected {
    height: 42px !important;
    width: 42px !important;
    padding: 2px !important;
}
.msc-product__properties h3 {
  text-transform: uppercase !important;
  color: $gray !important;
  font-size: 12px !important;
  padding: 0 !important;
}
.msc-product__properties ul {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  text-transform: uppercase;
}
.msc-product__properties .availability {
  margin-top: 5px;
}
.pdp-content-area2 {
  background-color: $msv-white !important;
  margin: 5px 0px 5px 5px !important;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
  padding: 10px !important;
}
.msc-choice-summary__clear-all {
    font-size: 14px !important;
}
.msc-swatch-container__item__image {
    display: inline-block !important;
    box-shadow: none !important;
    margin: 5px !important;
    &:hover {
        box-shadow: none !important;
        border: solid 1px $grey-light;
    }
}
.msc-product__properties-heading .msc-price__actual {
    display: block;
    font-size: 16px;
    font-weight: 500 !important;
}

.msc-product__data-container .product_detail-container {
    width: 55%;
    float: right;
    padding: 20px 10px 0;
}

.msc-product__data-container .product_detail-container .msc-product__properties {
    margin: 0px !important;
    padding: 0px !important;
    box-shadow: none !important;
    background-color: transparent;
    float: left;
    width: 100% !important;
}
.msc-product__data-container .product_detail-container .msc-product__properties h3 {
    padding: 10px 0 6px 0px !important;
}
.ms-refine-submenu-item.multi-select::before,
.ms-refine-submenu-item.multi-select-checked::before,
.ms-refine-submenu-item.single-select::before,
.ms-refine-submenu-item.single-select-checked::before {
    font-size: 14px !important;
}
.msc-rating__count {
    font-size: 12px !important;
}
.msc-choice-summary__item {
    font-size: 14px !important;
    border-radius: 25px !important;
    padding: 5px 12px !important;
}

.ms-search-result-container .ms-search-result-container__refine-product-section {
    display: block !important;
    margin-bottom: 20px !important;
}
.ms-search-result-container
    .ms-search-result-container__refine-product-section
    .ms-search-result-container__product-section
    .ms-search-result-wrapper-title-choice-summary {
    padding-top: 25px;
}
.ms-search-result-container
    .ms-search-result-container__refine-product-section
    .ms-search-result-container__refiner-section
    .ms-refine-submenu.list-group
    span {
    font-size: 14px !important;
}
.ms-refine-submenu__list .Color span {
    font-size: 12px !important;
}
.ms-search-result-container__refine-product-section button {
  margin-left: 0px !important;
  padding-left: 0px !important;
  background: transparent !important;
  font-size: 16px !important;
}
.sudwoll-plp-result
  .ms-search-result-container__refine-menu
  .ms-refine-submenu
  .msc-btn {
  border-bottom: 1px solid $msv-gray-300;
  font-size: 14px !important;
  text-align: left;
  padding-top: 15px;
  display: inline-flex;
  align-items: center;
}
.msc-alert-danger {
  background-color: $msv-white !important;
  border: none !important;
  font-size: 14px !important;
}
.order-template-lines1 h1 {
    padding-left: 10px !important;
}
.order-template-lines1 .ms-table__heading-row {
  background-color: $concrete;
  font-size: 14px !important;
}
.msc-add-line-to-template__search-button {
  background-color: $california !important;
}

.msc-lines-added-to-cart-dialog__dialog__header .msc-modal__title span {
    display: block;
    text-align: center;
    padding-top: 10px;
}
.order-template-lines1 .ms-order-template-table-line {
  border-bottom: solid 1px $concrete;
}
.order-template-lines1 .ms-order-template-table-line:hover {
  background-color: $concrete;
}

.msc-add-order-template-to-cart__dialog__back-to-order-template {
  background-color: $msv-white !important;
  color: $msv-black !important;
  border: solid 1px $msv-black !important;
}

.product-header {
    padding: 0px 0px 20px 0px !important;
    font-weight: 700 !important;
    li {
        span {
            &:nth-child(1) {
                padding: 0 0px 0 45px;
                width: 365px;
                display: inline-block;
            }
            &:nth-child(2) {
                width: 378px;
                display: inline-block;
            }
            &:nth-child(3) {
                width: 255px;
                display: inline-block;
            }
        }
    }
}

.msc-add-to-order-template-dialog__existing__dialog__order-templates-line__container__create-new-line__button {
    margin: 45px 55px !important;
}
.ms-checkout-customer-account {
    padding-top: 0px !important;
}
.msc-modal .msc-add-to-order-template-dialog__existing__dialog__order-templates-line__container__create-new-line {
    display: block !important;
}
.order-template-area1 {
  background-color: $msv-white !important;
  margin: 15px 0px 0px 0px !important;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
  display: flex;
  flex-direction: column;
}
.order-template-area1 div {
    order: 2;
}
.ms-order-template__list-item {
    order: 1;
}
.order-template-area h1,
.order-template-lines h2 {
  background-color: $msv-white;
  font-weight: initial;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
  font-size: 38px;
  padding: 19px 0px !important;
  margin: 15px 0px 5px 0px !important;
  text-align: center;
  line-height: normal !important;
  width: 100%;
}

.msc-empty_image,
.msc-add-to-order-template-dialog__existing__dialog__order-templates-line__products,
.msc-lines-added-to-order-template-dialog__dialog__products {
    display: none !important;
}
.msc-modal .msc-add-to-order-template-dialog__existing__dialog__order-templates-line__container {
    display: block !important;
    min-height: 100% !important;
    height: 150px !important;
}
.msc-add-to-order-template-dialog__existing__dialog__order-templates-line__products__image-container {
    display: block !important;
    min-height: auto !important;
}
.ms-order-template__image-container {
    display: none !important;
}
.msc-address-list__primary-list {
    display: inline-block !important;
    margin-left: 20px !important;
    @media (max-width: $msv-breakpoint-m) {
      margin-left: 0 !important;
    }
}
.ms-checkout__line-item .msc-cart-line__content {
     margin-left: 0px !important;
 }
.ms-checkout__btn-keep-shopping {
    margin-bottom: 20px !important;
}
.btn-secondary:hover {
  color: $msv-black !important;
}
.ms-account-customer-balance-tile {
    display: none !important;
}
.ms-buybox__quantity .ms-buybox__product-quantity-label {
    display: block !important;
    .msc-alert  {
      display: none;
    }
}

.quantity-label {
    padding-right: 5px !important;
}

.ms-buybox__product-quantity-label-heading {
  font-size: 12px !important;
  font-weight: bold !important;
  color: #2C262D !important;
}

.ms-buybox__product-quantity-label-heading::before{
  font-size: 12px !important;
  font-weight: bold;
  color: $msv-gray-20;
}
.ms-order-history__sales-line-image {
    display: none !important;
}
.ms-order-history__ms-order-history-filter .msc-popover button {
    text-align: left !important;
}
.msc-order-summary__heading {
    margin: 20px 10px 0px 10px !important;
    padding: 0px 0px 20px 0px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}
.ms-checkout__side-control-first .ms-checkout__btn-keep-shopping {
    width: 95% !important;
    margin: 0px 10px !important;
}
.ms-checkout__line-items-heading {
    margin: 0 !important;
    padding: 0px 0px 20px 0px !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}
.msc-order-summary__line-sub-total {
    padding: 25px 10px 0 !important;
}
.ms-checkout__side .msc-order-summary-wrapper .msc-order-summary__items {
    margin: 0 3px !important;
}
.msc-order-summary__line-total {
    margin: 10px !important;
    padding: 38px 0px 0px 0px !important;
}
.msc-promo-code-heading,
.ms-checkout__line-items-header,
.ms-checkout__line-items-delivery-group {
    margin: 20px 10px 10px 10px !important;
    padding: 0px 0px 20px 0px !important;
}
.msc-order-summary__line-shipping,
.msc-order-summary__line-tax-amount {
    padding: 25px 10px 0 !important;
}

.msc-promo-code__apply-btn::before {
  color: $msv-white !important;
}
.msc-order-summary__checkout__action {
    padding-bottom: 20px !important;
    text-align: center !important;
}
.msc-cart-lines-group-wrapper__bopis-heading-title {
    margin-left: 5px !important;
    display: inline-block !important;
}
.msc-cart-lines-group-wrapper__bopis-heading-shipping {
    margin: 15px 0px 15px 25px !important;
    display: inline-block !important;
}
.ms-checkout__guided-card-body {
    margin-top: 10px !important;
}
.msc-cart-line__remove-item {
    text-align: left !important;
}
.ms-checkout__line-items-delivery-group .ms-checkout__line-items-group-title {
    font-size: 18px !important;
    font-weight: 500 !important;
}
.msc-cart__btn-guestcheckout {
    margin: 15px 10px 5px !important;
}
.msc-modal__title {
    font-size: 24px !important;
}
.msc-add-to-cart__dialog__body span {
    display: block !important;
    padding: 5px 0px !important;
}
.msc-add-to-cart__dialog__go-to-cart {
    width: 100%;
    padding: 15px 20px !important;
    text-align: center !important;
    display: block !important;
}
.msc-add-to-cart__dialog {
    max-width: 400px !important;
}
.msc-add-to-cart {
  background-color: $nandor !important;
  border-radius: 50px;
  color: $msv-white !important;
  width: 75% !important;
  height: 50px !important;
  font-size: 18px !important;
  float: left;
  @media (min-width: 767px) and (max-width: $msv-breakpoint-l) {
    width: 60% !important;
    height: 44px !important;
    font-size: 16px !important;
  }
}
.msc-add-to-cart__dialog__back-to-shopping,
.ms-cart-icon__btn-gotocart {
    border-radius: 50px;
}
.product-add-to-cart .buttons {
    text-align: center !important;
    display: flex;
    flex-wrap: wrap;
    .add-to-template-wrapper,
    .msc-add-to-wishlist {
      max-width: 12.5%;
      @media (min-width: 767px) and (max-width: $msv-breakpoint-l) {
        max-width: 20%;
      }
    }
}
.ms-business-sign-up__form-item-input:active {
    box-shadow: none !important;
}
.ms-business-sign-up__container {
    margin-top: 15px !important;
}
 .ms-order-confirmation__sales-line .msc-cart-line__product-savings {
     display: inline-block !important;
}
.nav-item-link-text:hover {
    text-decoration: none !important;
}
.sud-nav .ms-nav__feature {
  background-color: $msv-white !important;
}
.sud-nav .level-2,
.sud-nav .level-2 li {
    display: block !important;
}
.ms-order-confirmation__sales-lines {
    display: block !important;
}
.ms-order-confirmation__sales-line .msc-cart-line__product,
.msc-cart-line__quantity {
  display: block !important;
}
.ms-business-sign-up
  .ms-business-sign-up__form
  .ms-business-sign-up__form-item-label,
.ms-business-sign-up
  .ms-business-sign-up__form
  .msc-address-form
  .msc-address-form__label {
  font-size: 13px;
}
.ms-business-sign-up
  .ms-business-sign-up__form
  .ms-business-sign-up__form-item-input,
.ms-business-sign-up
  .ms-business-sign-up__form
  .msc-address-form
  .msc-address-form__input {
  border: none;
  border-radius: 0px !important;
  background-color: transparent;
  border-bottom: 1px solid $buckthorn;
  height: 28px !important;
  font-size: 14px !important;
}
.ms-business-sign-up
  .ms-business-sign-up__form
  .ms-business-sign-up__form-item-input-area {
  border: none;
  border-radius: 0px !important;
  background-color: transparent;
  border-bottom: 1px solid $buckthorn;
  font-size: 14px !important;
}
.ms-business-sign-up .ms-business-sign-up__form .msc-address-form__dropdown {
  height: 34px !important;
  border: none;
  border-radius: 0px !important;
  background-color: transparent;
  border-bottom: 1px solid $buckthorn;
  font-size: 14px !important;
  padding: 0px !important;
}
.ms-business-organization-list__buttonBar,
.ms-table__container {
  background-color: $msv-white !important;
  padding: 20px;
}

.ms-business-sign-up__form,
.msc-invoices-list {
  background-color: $msv-white !important;
  margin: 15px 0px 0px 0px !important;
  padding: 20px;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
}

.ms-business-sign-up .ms-business-sign-up__form .ms-business-sign-up__cancel-button {
    border-radius: 10px;
}
.ms-header__profile-button {
  background-color: transparent !important;
  color: $nandor !important;
} 
.ms-checkout__line-item .msc-cart-line__product-image {
     display: none !important;
 }
// .msc-cart-line__product-quantity-label {
//     font-size: 0px !important;
// }
// .msc-cart-line__product-quantity-label::before {
//     content: 'Kg:';
//     font-size: 14px !important;
// }
.ms-business-sign-up .ms-business-sign-up__heading,
.ms-business-organization-list__heading {
  background-color: $msv-white;
  font-weight: initial;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
  font-size: 38px;
  padding: 19px 0px !important;
  margin-bottom: 5px !important;
  text-align: center;
  line-height: normal !important;
  @media (max-width: $msv-breakpoint-m) {
    font-size: 28px;
  }
}
.ms-account-management-address .msc-address-list__primary .msc-address-list__primary-list {
    border: none !important;
}
.ms-account-wishlist-tile {
    margin-top: 10px !important;
}
.wishlist1 .ms-wishlist-items__product-image {
    display: none !important;
}
.request-confirmation .ms-content-block[data-m-layout='full-width'] .ms-content-block__details {
    position: relative !important;
}
.order-confirmation .row {
  border: none !important;
  background-color: $msv-white;
  box-shadow: 0px 0px 20px 0px $grey-light !important;
  padding: 20px !important;
}
.request-confirmation h2 {
  color: $msv-black !important;
  font-size: 80px !important;
  letter-spacing: -5px !important;
}
.ms-order-confirmation__sales-line .msc-cart-line {
    width: 100% !important;
}
.request-confirmation .msc-cart-line__product-unit-price {
    display: none !important;
}
.order-confirmation .msc-cart-line__product-unit-price {
    display: none !important;
}
.order-confirmation .msc-cart-line__product-title {
    font-size: 24px !important;
    font-weight: 700 !important;
    display: block !important;
    padding-bottom: 10px !important;
}
.order-confirmation .quantity-label {
    margin: 0px !important;
}
.request-confirmation p {
  color: $msv-black !important;
}
.ms-account-profile__toggle-button {
    font-size: 32px !important;
}
.msc-promo-code__input-box {
  border: solid 1px $msv-gray-50 !important;
}
.ms-nav.ms-nav__disable-top-menu
    > .ms-nav__list
    > .ms-nav__list__item
    > .ms-nav__feature
    > .ms-nav__list
    > .ms-nav__list__item
    .ms-nav__list__item__link::before {
    display: none !important;
}

.msc-price__strikethrough {
    text-decoration: line-through;
}
.msc-autoSuggest__search-title-container .ms-search__form-cancelSearch {
    display: block !important;
    background-color: transparent !important;
}
.msc-autoSuggest__search-title-container .ms-search__form-cancelSearch::before {
  font-family: "Remix Icon";
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  text-rendering: auto;
  line-height: 1;
  content: "";
  width: 14px;
  text-align: center;
  color: $msv-white;
  font-size: 24px;
  vertical-align: middle;
}
.ms-nav__list__item__link:focus {
    border: 0px !important;
}
.msc-search-result-modal .msc-modal__header .msc-modal__title {
    text-transform: uppercase;
}
.full-page-loader {
    height: 100vh;
    width: 100%;
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.msc-waiting-circular {
  width: 92px !important;
  height: 92px !important;
  border-width: 7px !important;
  border-top-color: $california !important;
}
.sudwoll-carousel-area .ms-content-block__details {
    background-color: transparent !important;
    margin: 10% 60px 60px 60px !important;
}
.certificates span:nth-child(2) {
    text-transform: lowercase;
}
.certificates span:nth-child(2)::first-letter {
    text-transform: uppercase !important;
}
.ms-order-template__tile .ms-order-template__add-to-bag-button-div .msc-add-order-template-to-cart {
    width: 96% !important;
}
.ms-refine-submenu button {
    pointer-events: auto !important;
    cursor: pointer !important;
}
.ms-search-result-container__refiner-section .collapse:not(.show) {
    display: none !important;
}
@media (min-width: 1000px) and (max-width: 1200px) {
    .msc-product__data-container .product_detail-container {
        width: 46%;
        margin: 10px;
        padding: 20px !important;
    }

    .msc-product__data ul li span:first-child {
        width: 50%;
    }
}
@media (min-width: 1201px) and (max-width: 1378px) {
    .msc-product__data-container .product_detail-container {
        padding: 20px 20px 0 !important;
        width: 55% !important;
    }
}

@media (min-width: 993px) and (max-width: 1024px) {
 
    .sudwoll-carousel1 .ms-content-block__details{
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      h1{font-size: 80px !important;}
    }
    .msc-product__data-container .product_detail-container {
        padding: 20px !important;
    }
}
@media (min-width: 1138px) and (max-width: 1200px) {
    .msc-product__data ul li span:first-child {
        width: 130px;
    }
    .msc-product__data-container .product_detail-container {
        width: 48%;
        padding: 20px !important;
    }
}
@media (min-width: 1024px) and (max-width: 1025px) {
    .msc-product__data-container .product_detail-container {
        width: 48%;
        margin-left: 10px;
        padding: 20px !important;
    }
    .msc-product__data ul li span:first-child {
        width: 50% !important;
    }
}


@media (min-width: 767px) and (max-width: 992px) { 
  .sudwoll-carousel1 .ms-content-block__details {
    h1 {
        font-size: 80px !important;}
    }
  .sudwoll-carousel1.ms-content-block[data-m-layout="full-width"] .ms-content-block__details {
    background-color: transparent !important;
  }
    .ms-video-player__details {
        background-color: transparent !important;
    }
    .ms-buybox .ms-buybox__content .ms-buybox__configure {
        margin: 25px 12px 0 0 !important;
        clear: both;
        width: 100% !important;
    }
    .msc-product__data ul li span:first-child {
        width: 50% !important;
    }
    .msc-product__data-container .product_detail-container {
        padding: 20px !important;
    }
    .msc-product__data-container .product_detail-container .msc-product__properties h3 {
        padding: 10px 0 6px 10px !important;
    }
    .ms-search__searchForm {
        height: 46px !important;
    }
    .msc-product__composition-item {
        padding-left: 0;
    }
    .ms-header .ms-cart-icon {
        padding-left: 0px !important;
    }
    .ms-search-result-container .msc-button {
        width: 100% !important;
    }
    .msc-add-to-cart__dialog {
        left: 25% !important;
    }
    .sud-nav .level-2,
    .sud-nav .level-2 li {
        width: 100% !important;
    }
    .ms-search-result-container__title {
        max-width: 100% !important;
    }
    .sudwoll-header .ms-nav__list__item__link,
    .sudwoll-header .ms-nav__list__item__button {
        font-size: 11px !important;
    }
    .ms-nav__list {
        display: flex !important;
    }
    .ms-nav__list__item__link img {
        width: 63px !important;
        height: auto !important;
    }

    .ms-header__container .ms-header__topbar .ms-header__nav-icon {
        padding: 0px !important;
        margin: 0px !important;
    }
}

@media (min-width: 768.5px) and (max-width: 992px){
    .ms-header .ms-search .ms-search__label.bx-show .ms-search__icon {
      display: none !important;
  }
}

@media (min-width: 800px) and (max-width: 900px) {
    .ms-nav > .ms-nav__list > .ms-nav__list__item {
        margin-top: 0px !important;
        display: inline-block !important;
        margin: 0px !important;
    }
    .ms-header .ms-search .ms-search__label.bx-show .ms-search__icon {
        display: none;
    }
    .our-story3 .ms-iframe__content {
        width: 100% !important;
        margin: 0px !important;
    }
    .our-story4 .ms-content-block__details,
    .our-story5 h2 {
        position: unset !important;
    }
    .category-container {
        width: 47% !important;
    }
}
@media (max-width: 768px) {
  .ms-header__container .ms-header__topbar .ms-search {
    margin-right: 0;
  }
  .msc-modal__dialog:not(.msc-modal-input-required) {
    margin: auto !important;
  }
  .ms-content-block[data-m-layout="full-width"] .ms-content-block__details {
    margin: 0px !important;
    padding: 10px 10px 25px 10px !important;
    background-color: transparent !important;
  }
  .ms-search .ms-search__form-submitSearch {
    display: none !important;
  }
  .ms-search__form-cancelSearch {
    display: block !important;
  }
  .sudwoll-header .ms-nav__list__item__link,
  .sudwoll-header .ms-nav__list__item__button {
    font-size: 11px !important;
  }
  .ms-search__icon {
    background-color: transparent !important;
    color: $california !important;
    padding-left: 10px !important;
    display: inline-block;
  }
}

@media screen and (max-width: 767px) { 
  .ms-content-block[data-m-layout="full-width"] .ms-content-block__details {
    background-color: $msv-white !important;
  }
  .ms-checkout__main {
    margin-right: 0px !important;
    margin-bottom: 25px !important;
  }
    .video-txt-area .msc-cta__primary {
        margin-top: 0px !important;
    }
  .msc-product__data-container .product_detail-container {
    padding: 20px !important;
    width: 100%;
  }
  .ms-header__container .ms-header__topbar .ms-cart-icon-wrapper {
    margin-left: 3px;
  }
  .msc-swatch-container {
    width: 92% !important;
  }
  .sud-nav .ms-nav__feature {
    background-color: $msv-gray-20 !important;
  }
  .ms-search-result-container__title {
    max-width: 100% !important;
  }
  .ms-search-result-container
    .ms-search-result-container__Products
    .ms-product-search-result__item
    .msc-product__dimensions {
    margin: 0px !important;
  }

  .msc-product__composition-item {
    padding-left: 0px !important;
  }
  .ms-search .ms-search__form-submitSearch {
    display: none !important;
  }
  .ms-nav__list__item .ms-nav__list .ms-nav__list__item__button:after {
    float: right !important;
  }
  .nav-menu-container .collapse:not(.show) {
    display: block !important;
  }
  .ms-search-result-container .msc-sort-filter-wrapper .msc-button::before {
    padding-left: 5px !important;
  }
  .ms-header__container .ms-header__topbar .ms-header__nav-icon {
    color: $msv-black !important;
    display: block !important;
  }
  .ms-search__form-cancelSearch,
  .ms-search__icon {
    display: block !important;
    background-color: transparent !important;
    border: none !important;
    color: $california !important;
  }

  .ms-header__container .ms-header__topbar .ms-search {
    border: none !important;
    margin: 0px !important;
  }
  .sudwoll-header {
    height: auto !important;
  }
  .ms-nav__list {
    display: block !important;
  }
  .msc-autoSuggest__productResults-empty-mobile-text-content {
    display: none !important;
  }
  .ms-search__form-cancelSearch,
  .ms-search__form-control__clear {
    display: none !important;
  }
  .ms-search__form-control {
    padding-right: 10px !important;
    background-color: transparent !important;
    color: $msv-white !important;
  }
  .nav-menu-container nav ul li a:active,
  .nav-menu-container nav ul li a:hover {
    color: $msv-white !important;
    font-weight: normal !important;
    text-decoration: none !important;
    background-color: transparent !important;
  }
  .sudwoll-plp-result
    .ms-product-search-result__item
    .msc-product
    .msc-product__title {
    padding: 0px;
    font-weight: 700;
  }
  .ms-search-result-container .msc-sort-filter-wrapper {
    background-color: $msv-white !important;
  }
  .card {
    border: none !important;
  }
  .ms-cart .msc-cart-line__product {
    margin-left: 0px !important;
  }
  .ms-search__form-cancelSearch {
    display: block !important;
  }
    .ms-header__signin-button {
        padding: 0px !important;
        width: 36px !important;
    }
    .ms-nav__list__item{display: flex !important;}
    .sud-nav .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button:after{ float: right !important;}
    .ms-nav>.ms-nav__list>.ms-nav__list__item>.ms-nav__list__item__button{float: none !important;}
    .nav-item-link-text{float: left !important;}  
    .msc-product__properties, .msc-product__data{ width: 100% !important;}
    .ms-cart__estimated-total, .msc-order-summary__checkout-button{display: none !important;} 
}

.full-page-loader{display: none !important;} 

// new css override


.footer-copyright {
  margin-top: 15px;
}
ul.footer-social {
  display: flex;
  flex-wrap: wrap;
  @media (max-width: $msv-breakpoint-m) {
    justify-content: center;
  }
}
li.footer-social:first-child {
  width: 100% !important;
}

.footer-media-icon img {
  width: 30px;
  height: auto;
  margin-right: 10px;
  @media (max-width: $msv-breakpoint-m) {
    margin-right: 0;
    margin-left: 10px;
  }
}

.ms-account-b2b-requests-tile {
  margin-top: 0 !important;
}
.ms-order-confirmation__order-information-qr-code,
.ms-search-result-container .ms-search-result-container__Products .ms-product-search-result__item .msc-product__image,
.ms-order-confirmation .msc-cart-line__product-unit-of-measure {
  display: none !important;
}

// date picker reset 

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  left: unset !important;
  right: 16px;
  transform: unset !important;
  z-index: 1;
}
.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle {
  left: unset !important;
  transform: none !important;
  right: 16px;
  z-index: 1;
}
.react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
  background: none;
  color: inherit;
}

.react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected {
  background: none;
  color: inherit;
}
.react-datepicker__day.react-datepicker__day--selected {
  background: $california;
  color: $msv-white;
}

// hide cookie information

.CookieDeclaration {
  display: none !important;
}
.buybox-error-msg{
  margin-top: 8px;
  width: 100%;
}