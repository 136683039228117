$buybox-content-items-padding-top: 40px;
$buybox-button-height: 32px;
$buybox-margin-top: 36px;
$buybox-label-margin-bottom: 5px;
$gutter-width: 40px;
$media-gallery-width: 60%;
$msv-dialog-width: 400px;
$msv-dialog-button-margin: 15px auto;
$msv-dialog-button-padding: 6px 16px 7px;
$msv-dialog-button-width: 100%;
$buybox-quantity-input: 5px;
$buybox-incr-decr: 5px;
$msv-quantity-input-margin: 8px;
$msv-buybox-quality-controls-dimension: 52px;
$msv-buybox-inventory-margin-top: 20px;
$msv-buybox-depth: 0 1.6px 3.6px rgba(0, 0, 0, 0.132), 0 0.3px 0.9px rgba(0, 0, 0, 0.108);
$msv-buybox-ratings-section-padding-bottom: 25px;
$msv-buybox-ratings-section-sku-text-padding-top: 5px;
$msv-buybox-find-section-padding-top: 45px;
$msv-buybox-find-section-padding-bottom: 25px;
$msv-buybox-find-in-store-pickup-option-padding-bottom: 20px;
$msv-buybox-find-section-li-margin-right: 10px;
$msv-buybox-pickup-options-padding-top: 45px;
$msv-buybox-pickup-options-padding-bottom: 25px;
$msv-buybox-find-in-store-description-padding-bottom: 25px;
$msv-buybox-pickup-option-padding-bottom: 25px;
$msv-buybox-pickup-option-before-padding-right: 5px;
$msv-buybox-product-title-padding-bottom: 28px;
$msv-buybox-description-section-padding: 40px 0 15px 0;
$msv-buybox-description-section-text-padding-bottom: 22px;
$msv-buybox-quantity-padding-top: 30px;
$msv-buybox-quantity-padding-bottom: 30px;
$msv-buybox-quantity-text-message-padding-top: 5px;
$msv-buybox-minmax-label-text-before-padding-right: 5px;
$msv-buybox-price-section-padding-top: 26px;
$msv-buybox-price-section-text-padding-bottom: 5px;
$msv-buybox-price-actual-padding-bottom: 35px;
$msv-buybox-msc-alert-padding-bottom: 10px;
$msv-buybox-msi-exclamation-triangle-before-padding-right: 5px;
$msv-buybox-add-to-wishlist-container-padding-top: 15px;
$msv-buybox-add-to-wishlist-container-padding-bottom: 0;
$msv-buybox-ms-wishlist-items-product-status-padding-top: 10px;
$msv-buybox-find-in-store-padding-top: 15px;
$msv-buybox-description-text-padding-top: 40px;
$msv-buybox-description-text-padding-bottom: 20px;
$msv-buybox-shopsimilarlooks-padding-right: 24px;
$msv-buybox-shopsimilardescriptions-padding-left: 24px;
$msv-buybox-shop-similar-looks-button-padding-top: 25px;
$msv-buybox-shop-similar-looks-button-padding-bottom: 25px;

$msv-buybox-media-gallery-mobile-padding-right: 0;
$msv-buybox-product-title-mobile-padding-bottom: 10px;
$msv-buybox-ratings-section-sku-text-mobile-padding-top: 0;
$msv-buybox-ratings-section-rating-mobile-padding-top: 10px;
$msv-buybox-price-section-mobile-padding-top: 30px;
$msv-buybox-price-actual-mobile-padding-bottom: 40px;
$msv-buybox-quantity-mobile-padding-top: 40px;
$msv-buybox-quantity-mobile-padding-bottom: 35px;
$msv-buybox-add-to-cart-container-mobile-padding-top: 45px;
$msv-buybox-add-to-wishlist-container-mobile-padding-bottom: 0;
$msv-buybox-description-text-mobile-padding-top: 36px;
$msv-buybox-shopsimilarlooks-mobile-padding-right: 8px;
$msv-buybox-shopsimilardescriptions-mobile-padding-left: 9px;
$msv-buybox-media-gallery-tablet-padding-right: 0;
$msv-buybox-product-title-tablet-padding-bottom: 20px;
$msv-buybox-ratings-section-tablet-padding-bottom: 20px;
$msv-buybox-price-section-tablet-padding-top: 30px;
$msv-buybox-price-actual-tablet-padding-bottom: 40px;
$msv-buybox-strikethrough-price-font-size: 20px;
$msv-buybox-add-to-wishlist-icon-margin-right: 10px;
$msv-buybox-add-to-wishlist-icon-padding-top: 13.5px;
$msv-buybox-add-to-wishlist-icon-padding-bottom: 13.5px;
$msv-buybox-add-to-order-template-container-padding-bottom: 15px;
$msv-buybox-dropdown-margin-bottom: 25px;
$msv-buybox-dropdown-select-border-textbox: 0.5px solid $msv-gray-50;
$msv-buybox-dropdown-select-border-radius: 3px;
$msv-buybox-dropdown-select-focus-border: 1px solid $msv-blue;
$msv-buybox-dropdown-select-height: 52px;
$msv-buybox-media-gallery-padding-right: 33px;
$msv-buybox-media-gallery-thumbnail-item-margin-bottom: 20px;
$msv-buybox-media-gallery-thumbnail-item-margin-left: 20px;
$msv-buybox-media-gallery-thumbnail-item-width: 100%;
$msv-buybox-media-gallery-thumbnail-item-height: 100%;
$msv-buybox-media-gallery-vertical-item-width: 48%;
$msv-buybox-content-width: 25%;
$msv-buybox-content-ratings-section-padding-bottom: 27px;
$msv-buybox-content-description-section-padding-top: 47px;
$msv-buybox-content-quantity-padding-top: 38px;
$msv-buybox-content-quantity-padding-bottom: 28px;
$msv-buybox-content-add-to-cart-container-padding-top: 49px;
$msv-buybox-content-find-in-store-padding-top: 12px;
$msv-buybox-media-gallery-vertical-item-width-tablet: 47%;
$msv-buybox-media-gallery-vertical-item-width-large: 48%;
$msv-buybox-content-width-large: 17%;
$msv-preferred-store-availability-margin-top: 20px;
$buybox-custom-amount-input-padding: 5px;

//style presets
:root {
    //Background
    --msv-buybox-content-bg-color: #{$msv-white};
    //title
    --msv-buybox-title-font-size: var(--msv-body-font-size-xl);
    --msv-buybox-title-font-color: var(--msv-font-primary-color);
    //text
    --msv-buybox-text-font-size-title: var(--msv-body-font-size-l);
    --msv-buybox-text-font-size: var(--msv-body-font-size-s);
    --msv-buybox-text-font-size-description: var(--msv-body-font-size-m);
    --msv-buybox-text-font-color: var(--msv-font-primary-color);
    // primary button
    --msv-buybox-primary-btn-bg: var(--msv-accent-brand-color);
    --msv-buybox-primary-btn-font-color: var(--msv-font-secondary-color);
    --msv-buybox-primary-btn-border: var(--msv-accent-brand-color);
    // secondary button
    --msv-buybox-secondary-btn-bg: var(--msv-bg-color);
    --msv-buybox-secondary-btn-font-color: var(--msv-font-primary-color);
    --msv-buybox-secondary-btn-border: var(--msv-accent-brand-color);
    //Body
    --msv-buybox-quantity-text-color: #{$msv-gray-40};
    --msv-buybox-selected-button-color: #{$msv-gray-20};
    --msv-buybox-border-color: #{$msv-gray-50};
    --msv-buybox-depth-color: #{$msv-black};
    --msv-buybox-msc-alert-color: #{$msv-red};
    --msv-buybox-msc-inventory-available-text-color: #{$msv-blue};
    --msv-buybox-msc-add-wishlist-icon: #{$msv-blue};
}
// mixin

@mixin buybox-button {
    height: $buybox-button-height;
    width: 100%;
}

.ms-buybox {
    &__content {
        width: 100% !important;
        background-color: transparent !important;
    }

    .msc-product__data-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .article-details {
            display: flex;
            align-items: center;
            text-transform: uppercase;
            font-size: 12px;
            a {
                margin-left: 4px;
                cursor: pointer;
            }
        }
    }

    @media (min-width: $msv-breakpoint-s) {
        display: flex;
        flex-flow: row;

        &__content {
            @include font-body-regular-s();
            flex-grow: 2;
            background: var(--msv-buybox-content-bg-color);

            .msc-buybox__ratings-section {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                border-bottom: 0.5px solid $msv-gray-50;
                font-weight: normal;
                font-size: 14px;
                line-height: 20px;
                padding-bottom: $msv-buybox-ratings-section-padding-bottom;

                .msc-rating.msc-no-ratings {
                    margin-top: 0;

                    .msc-rating__range.sr-only {
                        display: none;
                    }

                    .msc-rating__group {
                        .msc-rating__star::before,
                        .msc-rating__empty-star::before,
                        .msc-rating__half-star::before {
                            font-size: 20px;
                        }

                        .msc-rating__half-star::after {
                            font-size: 20px;
                        }
                    }

                    .msc-rating__count {
                        font-size: 14px;
                        vertical-align: top;
                        color: var(--msv-buybox-quantity-text-color);
                        position: relative;
                        top: 2px;
                    }
                }
            }

            .msc-buybox__ratings-section-sku-text,
            .msc-buybox__description-text,
            .msc-buybox__description-section-text,
            .msc-buybox__price-section-text {
                @include font-body-regular-s();
                color: var(--msv-buybox-quantity-text-color);
            }

            .msc-buybox__ratings-section-sku-text {
                padding-top: $msv-buybox-ratings-section-sku-text-padding-top;
            }

            .msc-buybox__find-section {
                padding-top: $msv-buybox-find-section-padding-top;
                padding-bottom: $msv-buybox-find-section-padding-bottom;
                border-bottom: 0.5px solid var(--msv-buybox-border-color);

                .ms-buybox__find-in-store-pickup-option {
                    @include font-body-regular-s();
                    display: flex;
                    align-items: center;
                    padding-bottom: $msv-buybox-find-in-store-pickup-option-padding-bottom;
                }

                ul {
                    li:nth-child(3),
                    li:nth-child(4) {
                        display: none;
                    }

                    li:nth-child(1) {
                        &::before {
                            margin-right: $msv-buybox-find-section-li-margin-right;
                        }

                        @include add-icon($msv-store, before);
                    }

                    li:nth-child(2) {
                        &::before {
                            margin-right: $msv-buybox-find-section-li-margin-right;
                        }

                        @include add-icon($msv-DeliveryTruck, before);
                    }
                }
            }

            .ms-buybox__preferred-store-availability {
                margin-top: $msv-preferred-store-availability-margin-top;
                display: block;
            }

            .msc-buybox__pickup-options {
                padding-top: $msv-buybox-pickup-options-padding-top;
                padding-bottom: $msv-buybox-pickup-options-padding-bottom;
                border-top: 0.5px solid var(--msv-buybox-border-color);
                border-bottom: 0.5px solid var(--msv-buybox-border-color);
                font-size: 14px;

                .ms-buybox__find-in-store-description {
                    padding-bottom: $msv-buybox-find-in-store-description-padding-bottom;
                    color: var(--msv-buybox-quantity-text-color);
                }

                .ms-buybox__find-in-store-pickup {
                    .ms-buybox__find-in-store-pickup-option {
                        padding-bottom: $msv-buybox-pickup-option-padding-bottom;

                        @include add-icon($msv-InStore-Pickup);
                    }

                    .ms-buybox__find-in-store-pickup-option::before {
                        font-size: 20px;
                        padding-right: $msv-buybox-pickup-option-before-padding-right;
                        top: 2px;
                        position: relative;
                    }
                }
            }
        }

        .ms-buybox__add-to-order-template-container {
            text-align: center;
            padding-bottom: $msv-buybox-add-to-order-template-container-padding-bottom;
        }

        .msc-add-to-order-template-icon {
            @include icon-button-light();
            margin: 0 auto;

            &::before {
                font-size: $msv-icon-size-m;
                margin: 0 $msv-buybox-add-to-wishlist-icon-margin-right;
            }
        }

        &__product-title {
            color: var(--msv-buybox-title-font-color);
            background-color: $msv-white;
            font-weight: initial;
            box-shadow: 0px 0px 20px 0px $grey-light ;
            font-size: 38px;
            padding: 20px 0px;
            margin-bottom: 5px;
            text-align: center;
        }

        &__product-description {
            @include font-body-regular-m();
            display: inline;
        }

        .msc-buybox__description-section {
            padding: $msv-buybox-description-section-padding;

            &-text {
                padding-bottom: $msv-buybox-description-section-text-padding-bottom;
            }
        }

        &__quantity {
            border-bottom: 0.5px solid var(--msv-buybox-border-color);
            border-top: 0.5px solid var(--msv-buybox-border-color);
            padding-bottom: $msv-buybox-quantity-padding-bottom;

            .ms-buybox__product-quantity-label {
                display: none;
            }

            .quantity_text_message {
                padding-top: $msv-buybox-quantity-text-message-padding-top;

                .ms-buybox__minmax_label_text {
                    @include font-body-regular-s();
                    font-weight: 500;

                    .msi-InfoCircle {
                        @include add-icon($msv-information);

                        &::before {
                            font-size: 20px;
                            padding-right: $msv-buybox-minmax-label-text-before-padding-right;
                            top: 2px;
                            position: relative;
                            vertical-align: text-bottom;
                            color: $california;
                        }
                    }
                }
            }
        }

        &__key_in_price {
            margin-top: 20px;

            &-label {
                display: block;
                margin-bottom: $buybox-label-margin-bottom;
            }

            &_custom-amount__input {
                @include form-input-box();
                -moz-appearance: textfield;
                padding: $buybox-custom-amount-input-padding;
                text-align: left;

                &::-webkit-inner-spin-button,
                &::-webkit-outer-spin-button {
                    -webkit-appearance: none;
                }
            }
        }

        .msc-buybox__price-section {
            padding-top: $msv-buybox-price-section-padding-top;
            padding-bottom: $msv-buybox-price-actual-padding-bottom;

            &-text {
                padding-bottom: $msv-buybox-price-section-text-padding-bottom;
            }
        }

        .msc-price {
            display: flex;
            align-items: center;
        }

        .msc-price__actual {
            @include font-heading-h3-s();
            display: block;
            color: var(--msv-buybox-text-font-color);
        }

        .msc-price__strikethrough {
            @include font-heading-h5-l();
            font-size: 14px;
            // color: $msv-gray-50;
            color: $msv-gray-20;
            text-decoration-line: line-through;
            margin-right: 8px;
        }

        .ms-buybox__product-quantity-label-heading {
            @include font-body-regular-s();
            color: var(--msv-buybox-quantity-text-color);
        }

        &__media-gallery {
            display: block;
            padding-left: 20px;
            padding-top: 20px;
            padding-right: 20px;
        }

        &__add-to-cart-container {
            padding-top: $buybox-content-items-padding-top;

            .msc-add-to-cart {
                @include buybox-button();
                @include primary-button-light();
                text-transform: uppercase;
                height: 100%;
            }
        }

        .msc-alert {
            padding-bottom: $msv-buybox-msc-alert-padding-bottom;
            font-weight: 500;

            .msc-alert__header {
                margin-bottom: 8px;
                color: var(--msv-buybox-msc-alert-color);

                .msi-exclamation-triangle {
                    @include font-body-regular-s();
                    color: var(--msv-buybox-msc-alert-color);

                    @include add-icon($msv-information);
                }

                .msi-exclamation-triangle::before {
                    font-size: 20px;
                    padding-right: $msv-buybox-msi-exclamation-triangle-before-padding-right;
                    top: 2px;
                    position: relative;
                    vertical-align: sub;
                }
            }

            .msc-alert__line {
                color: $msv-red;
                margin-bottom: 8px;
            }
        }

        &__add-to-wishlist-container {
            padding-top: $msv-buybox-add-to-wishlist-container-padding-top;
            padding-bottom: $msv-buybox-add-to-wishlist-container-padding-bottom;

            .msc-add-to-wishlist {
                @include add-icon($msv-Heart, before);
                @include icon-button-light();
                margin: 0 auto;

                &::before {
                    margin-right: $msv-buybox-add-to-wishlist-icon-margin-right;
                    font-size: $msv-icon-size-m;
                    color: var(--msv-buybox-msc-add-wishlist-icon);
                }

                &__removing {
                    @include add-icon($msv-Heart-Fill, before);
                }

                &:hover {
                    @include add-icon($msv-Heart-Fill, before);
                }
            }
        }

        .ms-wishlist-items__product-status {
            display: block;
            position: unset;
            height: auto !important;
            width: 100% !important;
            margin-top: 25px;
        }

        &__product-unit-of-measure {
            @include font-body-regular-s();
            color: $msv-gray-40;
            margin-top: 8px;
        }

        /* stylelint-disable no-descending-specificity -- Suppressed. */
        &__find-in-store {
            padding-top: $msv-buybox-find-in-store-padding-top;

            .ms-buybox__find-in-store-heading,
            .ms-buybox__find-in-store-description,
            .ms-buybox__find-in-store-pickup {
                display: none;
            }

            .ms-buybox__find-in-store-button {
                @include buybox-button();
                @include secondary-button-light();
                text-transform: uppercase;
                height: 100%;

                &:not(:disabled) {
                    &:hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
    //mobile vp

    @media (max-width: ($msv-breakpoint-m)-1) {
        flex-flow: column;
        &__content {
            width: 100%;

            .ms-buybox__product-title {
                font-size: 24px;
                padding-left: 16px;
                font-size: 20px;
                padding-right: 16px;
            }

            .msc-buybox__ratings-section {
                display: block;

                .msc-buybox__ratings-section-sku-text {
                    padding-top: $msv-buybox-ratings-section-sku-text-mobile-padding-top;
                }

                .msc-rating {
                    padding-top: $msv-buybox-ratings-section-rating-mobile-padding-top;
                }
            }

            .msc-buybox__price-section {
                padding-top: $msv-buybox-price-section-mobile-padding-top;
                padding-bottom: $msv-buybox-price-actual-mobile-padding-bottom;
            }

            .ms-buybox__quantity {
                padding-bottom: 18px;
            }

            .ms-buybox__add-to-cart-container {
                padding-top: $msv-buybox-add-to-cart-container-mobile-padding-top;
            }

            .ms-buybox__add-to-wishlist-container {
                padding-bottom: $msv-buybox-add-to-wishlist-container-mobile-padding-bottom;
            }

            .msc-add-to-order-template-icon,
            .msc-add-to-wishlist {
                padding-left: 0;
                padding-right: 0;
            }

            .msc-buybox__description-text {
                padding-top: $msv-buybox-description-text-mobile-padding-top;
            }

            .msc-buybox__shop-description {
                .ms-buybox__shopsimilarlooks {
                    padding-right: $msv-buybox-shopsimilarlooks-mobile-padding-right;
                }

                .ms-buybox__shopsimilardescriptions {
                    padding-left: $msv-buybox-shopsimilardescriptions-mobile-padding-left;
                }
            }
        }

        .msc-price__actual {
            @include font-heading-h4-s();
        }
    }

    //tablet vp

    @media (min-width: $msv-breakpoint-m) and (max-width: ($msv-breakpoint-l)-1) {
        flex-flow: column;

        &__media-gallery {
            padding-right: 0;
        }

        &__content {
            width: 100%;

            .ms-buybox__product-title {
                @include font-heading-h2-m();
                padding-bottom: $msv-buybox-product-title-tablet-padding-bottom;
            }

            .msc-buybox__ratings-section {
                padding-bottom: $msv-buybox-ratings-section-tablet-padding-bottom;
            }

            .msc-buybox__price-section {
                padding-top: $msv-buybox-price-section-tablet-padding-top;
                padding-bottom: $msv-buybox-price-actual-tablet-padding-bottom;
            }
        }

        .quantity,
        .ms-buybox__add-to-cart-container,
        .ms-buybox__find-in-store,
        .ms-buybox__add-to-wishlist-container,
        .msc-buybox__shop-description,
        .msc-buybox__description-section,
        .ms-buybox__add-to-order-template-container {
            width: 50%;
        }

        .quantity {
            @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                width: 100%;
            }
        }

        .msc-price__actual {
            @include font-heading-h4-m();
        }
    }

    @media (min-width: ($msv-breakpoint-l)+1) {
        &__media-gallery {
            padding-right: $msv-buybox-media-gallery-padding-right;

            .ms-media-gallery {
                &.vertical {
                    .msc-ss-carousel-vert-slide {
                        .ms-media-gallery__thumbnail-item {
                            margin-right: 0;
                            margin-bottom: $msv-buybox-media-gallery-thumbnail-item-margin-bottom;
                            height: 100%;

                            &:nth-of-type(2n) {
                                margin-left: $msv-buybox-media-gallery-thumbnail-item-margin-left;
                            }

                            button.msc-ss-carousel-vert-button-wrapper {
                                width: $msv-buybox-media-gallery-thumbnail-item-width;
                                height: $msv-buybox-media-gallery-thumbnail-item-height;
                            }
                        }
                    }
                }

                .msc-ss-carousel-vert-item {
                    width: $msv-buybox-media-gallery-vertical-item-width;
                }
            }
        }

        &__content {
            width: $msv-buybox-content-width;

            .msc-buybox__ratings-section {
                padding-bottom: $msv-buybox-content-ratings-section-padding-bottom;
            }

            .msc-buybox__description-section {
                padding-top: $msv-buybox-content-description-section-padding-top;
            }

            .ms-buybox {
                &__quantity {
                    padding-top: 0;
                    padding-bottom: 16px;
                }

                &__add-to-cart-container {
                    padding-top: $msv-buybox-content-add-to-cart-container-padding-top;
                }

                &__find-in-store {
                    padding-top: $msv-buybox-content-find-in-store-padding-top;
                }
            }
        }
    }

    @media (min-width: ($msv-breakpoint-l)+1) and (max-width: ($msv-breakpoint-lg)-1) {
        .ms-media-gallery .msc-ss-carousel-vert-item {
            width: $msv-buybox-media-gallery-vertical-item-width-tablet;
        }
    }

    @media (min-width: $msv-breakpoint-xl) {
        &__media-gallery {
            .ms-media-gallery {
                .msc-ss-carousel-vert-item {
                    width: $msv-buybox-media-gallery-vertical-item-width-large;
                }
            }
        }

        &__content {
            width: $msv-buybox-content-width-large;
        }
    }

    label {
        display: block;
        margin-bottom: $buybox-label-margin-bottom;
        min-height: 20px;
        clear: both;
    }
    .master-btn {
        @include primary-btn();
        height: auto;
        padding: 5px 15px;
        @media (max-width: 575px) {
            margin: 10px 0 0;
        }
    }

    .msc-buybox__description-text {
        padding-top: $msv-buybox-description-text-padding-top;
        padding-bottom: $msv-buybox-description-text-padding-bottom;
    }

    .msc-buybox__shop-description {
        display: inline-flex;
    }

    .ms-buybox__shopsimilarlooks {
        padding-right: $msv-buybox-shopsimilarlooks-padding-right;
    }

    .ms-buybox__shopsimilardescriptions {
        padding-left: $msv-buybox-shopsimilardescriptions-padding-left;
        border-left: 0.5px solid $msv-gray-50;
    }

    .ms-buybox__dropdown {
        .msc-dropdown__select {
            @include font-body-regular-s();
            height: $msv-buybox-dropdown-select-height;
            background: linear-gradient(0deg, $msv-white, $msv-white), $msv-gray-100;
            width: 100%;
            border: $msv-buybox-dropdown-select-border-textbox;
            border-radius: $msv-buybox-dropdown-select-border-radius;
            outline: none;
            padding: 16px 12px;
            order: 1;

            option {
                @include font-body-regular-m();
            }

            &:focus {
                border: $msv-buybox-dropdown-select-focus-border;
                outline: none;
            }
        }
    }

    &__inventory-info {
        margin-top: $msv-buybox-inventory-margin-top;
    }

    &__shop-similar-looks-button,
    &__shop-similar-descriptions-button {
        border: none;
        background: var(--msv-buybox-content-bg-color);
        box-shadow: $msv-buybox-depth;
        border-radius: 10px;
        padding-top: $msv-buybox-shop-similar-looks-button-padding-top;
        padding-bottom: $msv-buybox-shop-similar-looks-button-padding-bottom;

        @include font-body-regular-m();
        @include vfi();
    }

    &__shop-similar-looks-button {
        @include add-icon($msv-eye, before);

        &::before {
            font-size: $msv-icon-size-m;
            width: 100%;
        }
    }

    &__shop-similar-descriptions-button {
        @include add-icon($msv-file, before);

        &::before {
            font-size: $msv-icon-size-m;
            width: 100%;
        }
    }

    @include quantity-controls-decoration();

    .quantity {
        .quantity-input {
            margin-left: $msv-quantity-input-margin;
            margin-right: $msv-quantity-input-margin;
            padding: $buybox-quantity-input;
            &:disabled {
                opacity: 0.8;
                cursor: default;
            }
        }

        .quantity__controls {
            padding: $buybox-incr-decr;
            padding-left: 0;
            padding-right: 0;
            max-width: $msv-buybox-quality-controls-dimension;
            width: 100%;
            &:disabled {
                opacity: 0.8;
                cursor: default;
                &:after {
                    opacity: 0.7;
                }
            }
        }
    }

    &__content {
        .ms-buybox__configure {
            margin: 0;
            clear: both;
            .ms-buybox__dropdown {
                .msc-alert {
                    @include alert();
                    @include add-icon($msv-information);
                    align-items: center;
                    display: flex;
                    align-content: baseline;
                    margin-top: 12px;
                    @media (max-width: $msv-breakpoint-m) {
                        width: 100%;
                        padding-top: 5px;
                    }
                }
            }
        }
    }
    .color-required-message {
        font-weight: 600;
        margin-bottom: 16px;
        display: flex;
        align-items: center;
        line-height: 25px;
        .msi-InfoCircle {
            @include add-icon($msv-information);

            &::before {
                font-size: 20px;
                padding-right: $msv-buybox-minmax-label-text-before-padding-right;
                top: 2px;
                position: relative;
                vertical-align: text-bottom;
                color: $california;
            }
        }
    }
    .msc-product__fibre-properties {
        margin-top: 16px;
    }
    .color-pdf {
        width: 50%;
        a {
            display: inline-flex;
            align-items: center;
            img {
                margin-left: 5px;
            }
        }
    }
    .product-thumbnails {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        .thumbnail-wrapper {
            picture {
                display: inline-flex;
                padding: 5px;
                margin: 0 5px;
                border: 1.5px solid transparent;
                cursor: pointer;
                border-radius: 4px;
            }
            &.active {
                picture {
                    border-color: $california;
                    box-shadow: 0 0.6px 1.8px $msv-pop-over-box-shadow-color, 0 3.2px 7.2px $msv-account-pop-over-box-shadow-color;
                }
            }
            img {
                width: 60px;
                height: 60px;
                @media (max-width: $msv-breakpoint-l) {
                    width: 50px;
                    height: 50px;
                }
            }
        }
    }
    .certificates {
        flex-wrap: wrap;
        padding-top: 10px;
        padding-bottom: 0;
        align-items: flex-start;
        .certificate-heading {
            width: 100%;
            color: $msv-gray-20 !important;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
        }
    }
    .certificates-images_container {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        margin-top: 5px;
        flex-wrap: wrap;
        .certificate-img {
            display: inline-block;
            margin-right: 5px;
            width: auto;
            max-width: calc(25% - 5px);
            img {
                width: auto;
                height: auto;
                max-width: 100px;
                max-height: 60px;
                width: 100%;
            }
        }
    }
    .ms-media-gallery {
        .ms-media-gallery__carousel {
            .msc_image{
                height: 350px;
                width: 350px;
                margin: 0 auto;
            }
        }
        .msc-carousel__control__prev__icon:before, .msc-carousel__control__next__icon:before {
            @media (min-width: $msv-breakpoint-l) {
                font-size: 32px;
            }
        }
        .msc-carousel__control__prev, .msc-carousel__control__next {
            @media (min-width: $msv-breakpoint-l) {
                height: 40px;
                width: 40px;
                background: transparent;
                color: $gray;
                margin: 5px;
                &:hover {
                    border: 1px solid $msv-white;
                }
            }
        }
    }
    .ms-media-gallery__thumbnails-container {
        img {
            width: 80px;
            height: 80px;
        }
        .msc-ss-carousel-slide {
            justify-content: center;
        }
    }
    .custom-dropdown-wrapper {
        margin-bottom: 16px;
    }


    .color-diff-message {
        padding: 0 20px 0;
        font-weight: 500;
        align-items: center;
        font-size: 12px;
        display: flex;
        @media (max-width: $msv-breakpoint-m) {
            padding-top: 0;
        }
    }

    .fullscreen-preview {
        position: fixed;
        cursor: default;
        top:0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
        background-color: rgba($black ,0.4);
        .content {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 50px 0;
            height: 100%;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
                padding: 50px 16px;
            }
            img {
                max-width: 100%;
                height: auto;
                max-height: 100%;
                margin: auto;
                cursor: default;
            }
        }
        .close {
            @include add-icon($msv-Cancel, after);
            position: absolute;
            top: 24px;
            right: 24px;
            color: $msv-black;
            font-size: 25px;
            opacity: 1;
            z-index: 1000;
        }
        .overlay {
            width: 100%;
            height: 100%;
        }
    }

    .product-discounts {
        p {
            color: $msv-green-10;
            font-weight: 500;
            font-size: 14px;
        }
    }
    .quantity-threshold-message {
        color: $msv-gray-20;
        margin-top: 0;
        margin-bottom: 28px;
        font-weight: 600;
        .msi-InfoCircle {
            &::before {
                font-size: 20px;
                color: $california;
            }
        }
    }
    .treatment-values {
        // display: flex;
        flex-wrap: wrap;
        max-width: 50%;
        & > div {
            margin-right: 5px;
        }
    }
}

.msc-modal {
    .msc-modal__dialog.ms-buybox__dialog {
        max-width: $msv-dialog-width;

        .msc-modal__content {
            max-width: $msv-dialog-width;
        }

        .ms-buybox__dialog__body {
            text-align: center;
        }

        .ms-buybox__dialog__image {
            margin-right: auto;
            margin-left: auto;
        }

        .ms-buybox__dialog__go-to-cart.msc-btn {
            @include primary-button-light();
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }

        .ms-buybox__dialog__back-to-shopping.msc-btn {
            @include secondary-button-light();
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }
    }

    .msc-modal__dialog.msc-add-to-cart__dialog {
        .msc-modal__content {
            max-width: $msv-dialog-width;
        }

        .msc-add-to-cart__dialog__body {
            text-align: center;
        }

        .msc-add-to-cart__dialog__image {
            margin-right: auto;
            margin-left: auto;
        }

        .msc-add-to-cart__dialog__back-to-shopping.msc-btn {
            @include secondary-button-light();
            display: block;
            margin: $msv-dialog-button-margin;
            padding: $msv-dialog-button-padding;
            width: $msv-dialog-button-width;
        }
    }
}

// PDP custom overrides

.pdp-content-area1 {
    display: block;
    padding: 10px;
    padding-bottom: 7px;
    .msc-add-to-wishlist__removing{
    &::before{    
        font-family: 'Remix Icon';
        content: "\EE0E" !important;
        font-size: 32px;
        color: red!important;
        @media (max-width: 575px) {
            font-size: 28px !important;
        }
    }
    }
    .msc-add-to-wishlist{
    &::before {
        font-family: 'Remix Icon';
        content: '';
        font-size: 32px;
        color: #545554 !important;
        @media (max-width: 575px) {
            font-size: 28px !important;
        }
    }
    }
    .msc-product-data-specification {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .msc-product__data {
        width: 66%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        vertical-align: top;
        box-shadow: 0px 0px 20px 0px $grey-light  !important;
        background-color: $msv-white !important;
        margin: 5px 5px 5px 0px !important;
    }
    .msc-add-to-wishlist {
        &::before {
            font-family: 'Remix Icon';
            content: '';
            font-size: 32px;
            color: $nandor !important;
        }
    }
    ul.msc-product__data-details,
    ul.fibre-blend {
        padding: 0px 0px 0px 0px;
        margin: 0px;
        display: inline-block;
        width: 100%;
    }
    .fibre-blend {
        li {
            padding: 0;
            margin: 4px 0;
        }
    }
    .msc-product__properties {
        width: 33%;
        float: left;
        background-color: $msv-white;
        padding: 20px;
        margin: 5px 0px 5px 5px;
        box-shadow: 0px 0px 20px 0px $grey-light  !important;
    }
    .ms-buybox__quantity {
        border: none;
    }
    .ms-buybox__dropdown {
        margin-top: 0;
    }
    .ms-buybox__product-description {
        display: none;
    }
    .ms-reviews-list {
        display: none;
    }
    .product-add-to-cart {
        margin-bottom: 16px;
    }
    .msc-product__care-labels h3 {
        text-transform: uppercase;
        color: $msv-gray-20 !important;
        font-weight: bold;
        font-size: 12px;
        padding: 20px 20px 0 0;
        @media (max-width: $msv-breakpoint-m) {
            display: flex;
            padding-top: 10px;
        }
    }
    .msc-product__care-labels ul {
        padding-top: 0;
        margin-top: 0;
        margin-bottom: 0;
        display: block;
    }
    .msc-product__care-labels ul li {
        display: inline-block;
        width: 50px;
        padding: 5px;
        padding-left: 0;
    }
    .ms-buybox__inventory-info {
        margin: 0 auto 10px;
        font-weight: 600;
        background-color: $msv-white;
        width: 33%;
        padding: 0 20px 0 20px;
        display: none;
    }
    .image_swatches-container {
        width: 45%;
        margin-bottom: 15px;
        margin-bottom: 15px;
        padding: 20px 0 0 20px;
        max-width: 370px;
        max-height: 350px;
    }
    .msc-product__data-image {
        display: inline-block;
        vertical-align: top;
        width: 370px;
        height: 325px;
        cursor: zoom-in;
        border: 1px solid #EDEDED;
        box-shadow: 0px 1px 10px rgba(151, 151, 151, 0.1);
    }
    .count {
        span {
            &:first-child {
                background-color: transparent;
                color: $gray;
                font-weight: 500;
                text-align: left;
                padding: 0px;
                width: 50%;
                margin: 0;
                display: inline-block;
                font-size: 12px;
            }
        }
        .micron-value {
            text-transform: lowercase;
        }
    }
    .availability {
        span {
            background-color: transparent;
            text-align: left;
            padding: 0px;
            width: 50%;
            margin: 0px;
            display: inline-block;
        }
        .value {
            color: $msv-gray-20;
            font-weight: 500;
            text-transform: none;
        }
    }
    .msc-product__properties-heading {
        &:first-child {
            padding-top: 0px;
        }
    }
    .msc-product__properties-heading {
        padding-top: 5px;
        font-weight: bold !important;
        color: $msv-gray-20 !important;
        text-transform: uppercase;
        font-size: 16px !important;
    }
    .color-categories {
        width: 100%;
        padding: 12px 0;
        button {
            @include secondary-button($nandor, $msv-white, $nandor);
            height: 30px;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            font-size: 12px;
            margin-right: 16px;
            min-width: auto;
            padding: 5px 16px;
            border-radius: 5px;
            margin: 5px 10px 5px 0px;
            &.active,
            &:hover {
                background: $california;
                border-color: $california;
            }
        }
    }
    .ms-buybox__configure {
        width: 100%;
        .msc-swatch-container {
            max-height: 215px;
            overflow-y: auto;
            display: inline-block;
            width: 100%;
            margin-left: -5px;
        }
    }
}
.color-heading{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    span,div{  font-size: 12px;}
    .color-label {
        padding-left: 0;
        padding-right: 0;
        color: $msv-gray-20;
        font-weight: bold;
        // div {
        //     &:first-child {
        //         margin-right: 5px;
        //     }
        // }
    }
    div {
        float: left;
        text-transform: uppercase;
    }
    span {
        font-weight: 500;
        color: $msv-black;
        padding: 0px 10px 0px 5px;
        float: left;
    }
}
.pdp-content-area {
    margin-top: 25px !important;
}

@media (min-width: 1000px) and (max-width: 1200px) {
    .pdp-content-area1 {
        .msc-product__properties {
            width: 32% !important;
        }
    }
}

@media (min-width: 993px) and (max-width: 1024px) {
    .pdp-content-area1 {
        .msc-product__properties {
            width: 32.7% !important;
        }
    }
}

@media (min-width: 1138px) and (max-width: 1200px) {
    .pdp-content-area1 {
        .msc-product__properties {
            width: 33%;
        }
        .image_swatches-container {
            width: 48%;
        }
    }
}

@media (min-width: 1024px) and (max-width: 1025px) {
    .pdp-content-area1 {
        .image_swatches-container {
            width: 48%;
        }
        .msc-product__properties {
            width: 32.5% !important;
        }
    }
}

@media (min-width: 767px) and (max-width: 992px) {
    .pdp-content-area1 {
        .msc-product__care-labels {
            h3 {
                padding: 0px !important;
            }
        }
        .msc-product__data {
            ul {
                margin: 0px 0px 5px 10px !important;
            }
        }
        .msc-product__data,
        .ms-buybox__configure {
            width: 65% !important;
        }
        .msc-product__properties {
            width: 33.5% !important;
        }
    }
}

@media screen and (max-width: 767px) {
    .pdp-content-area1 {
        .image_swatches-container {
            width: 100%;
            text-align: center;
            padding: 20px 20px 0;
            margin: 0 auto;
        }
        .msc-product__data-image {
            width: 100%;
            margin-left: 0;
        }
        .msc-product__data {
            ul {
                display: block !important;
            }
        }
        .ms-buybox__quantity {
            width: 100% !important;
            margin: 5px 0px 0px 0px !important;
        }
        .product-add-to-cart {
            width: 100% !important;
            margin: 10px 0px 16px 0px !important;
        }
        .msc-product__data {
            margin: 5px 0px 5px 0px !important;
        }
        .msc-product__properties {
            margin: 5px 0px 5px 0px !important;
        }
    }
}

// custom dropdown
.custom-dropdown-wrapper {
    position: relative;
    .swatch-wrap {
        width: 25px;
        height: 25px;
        border: 1px solid $white;
        margin-right: 8px;
        box-shadow: 0 0 0px 1px #d7d7d7;
        border-radius: 50%;
        overflow: hidden;
        display: inline-flex;
        padding: 2px;
        justify-content: center;
        align-items: center;
    }
    .color-item-swatch {
        width: 21px;
        height: 21px;
        min-width: 21px;
        min-height: 21px;
        max-width: 21px;
        max-height: 21px;
        border-radius: 50%;
    }
    .selected-dropdown-color {
        padding-bottom: 8px;
        border-bottom: 1px solid $california;
        position: relative;
        padding-right: 16px;
        cursor: pointer;
        display: flex;
        align-items: center;
        @include add-icon($msv-ChevronDown, after);
        &:after {
            position: absolute;
            right: 8px;
            font-size: 18px;
            top: 5px;
        }
    }
    ul {
        position: absolute;
        z-index: 2;
        background: $white;
        left: 0;
        right: 0;
        box-shadow: 0 2px 28px 0 rgba($black, 0.16);
        border-radius: 4px;
        padding: 5px;
        visibility: hidden;
        opacity: 0;
        transform: translateY(-20px);
        padding-top: 8px;
        max-height: 250px;
        overflow: auto;
        li {
            display: flex;
            padding: 8px;
            cursor: pointer;
            align-items: center;
            height: 41px;
            min-height: 41px;
            &.selected,
            &:hover {
                background: rgba($black, 0.1);
            }
        }
        &.opened {
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
            transition: all 0.3s;
        }
    }
    .overlay {
        position: fixed;
        z-index: 1;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        &.closed {
            display: none;
        }
    }
}

// inventory information

.inventory-information[class] {
    // list-style-type: decimal;
    // padding-left: 32px;
    color: $california;
    font-weight: 600;
    padding-bottom: 12px;
    text-transform: uppercase;
    ul {
        padding: 0;
        list-style: none;
        color: $msv-gray-20;
        // color: $gray;
        font-weight: 500;
        flex-direction: row;
        flex-wrap: wrap;
        li {
            padding-right: 8px;
            margin-right: 8px;
            display: inline-flex;
            border-right: 2px solid $california;
            font-size: 12px;
            &:last-child {
                border-right: 0;
                padding-right: 0;
                margin-right: 0;
            }
        }
    }
}
.maximum-lot-quantity-message{
    margin-bottom: 5%;
}