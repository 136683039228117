.our-story-hero  {
  .ms-content-block__details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 !important;
    .ms-content-block__title {
      font-size: 60px;
      text-align: center;
      display: block;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 42px;
      }
    }
  }
  &.ms-content-block[data-m-layout="full-width"] {
    .ms-content-block__details {
      .msc-cta__primary {
        @include primary-btn();
      }
    }
  }
  &.secondary {
    .ms-content-block__title {
      font-size: 36px;
      text-align: center;
      display: block;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 32px;
      }
      @media (max-width: $msv-breakpoint-l) {
        color: $msv-gray-20;
      }
    }
  }
}

.our-story-quote {
  padding: 40px 50px;
  margin: 0;
  h2 {
    span {
      position: relative;
      font-size: 42px;
      font-weight: 700;
      letter-spacing: -1px;
      color: $california;
      padding-left: 15px;
      @media (max-width: $msv-breakpoint-m) {
        display: block;
      }
      &::before {
        transform: translateX(-55%);
        content: "»";
        position: absolute;
        left: 0;
      }
      &::after {
        transform: translateX(10%);
        content: "«";
      }
    }
  }
}

.our-story-founders {
  img {
    margin: 0 auto;
  }
  .ms-content-block__text {
    font-size: 22px;
    font-weight: 600;
    margin: 32px 0;
  }
}

.our-story-book {
  .ms-iframe__heading {
    font-size: 36px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 36px;
  }
  iframe {
    width: 100%;
    border: 0;
  }
}

.our-story-creativity {
  display: flex;
  margin-top: 60px;
  @media (max-width: $msv-breakpoint-m) {
    flex-wrap: wrap;
  }
  .ms-content-block__image {
    width: 100%;
    @media (min-width: $msv-breakpoint-m) {
      min-width: 33%;
    }
  }
  .ms-content-block__title {
    background-color: $california;
    color: $msv-white;
    font-size: 48px;
    padding: 32px 32px 12px;
    font-weight: bold;
    position: relative;
    @media (max-width: $msv-breakpoint-m) {
      font-size: 32px;
    }
    &::before {
      content: "»";
      position: absolute;
      left: 4px;
    }
    &::after {
      transform: translateX(10%);
      content: "«";
    }
  }
  .ms-content-block__text {
    background-color: $california;
    padding-bottom: 32px;
    color: rgba($msv-black, 0.3);
    font-weight: 600;
    padding-left: 32px;
  }
  .ms-content-block__details {
    @media (min-width: $msv-breakpoint-m) {
      transform: translate(-30px, 30px);
    }
  }
  .ms-content-block__cta {
    text-align: right;
    .msc-cta__primary {
      @include primary-btn();
      background: transparent;
      color: $california;
      border: 1px solid $california;
      font-weight: 600 !important;
      &:hover,
      &:active,
      &:focus {
        background: $california ;
        color: $msv-white;
      }
      &:before {
        display: none;
      }
    }
  }
}

.our-story-mindset {
  margin-bottom: 32px;
  @media (min-width: $msv-breakpoint-l) {
    margin-top: 60px;
  }
  .ms-content-block__details {
    margin: 0;
    @media (min-width: $msv-breakpoint-l) {
      width: calc(100% + 100px);
      padding: 50px 0;
    }
  }
  &.ms-content-block[data-m-layout="left-right"] {
    .ms-content-block__title {
      font-size: 24px;
      font-weight: normal;
      color: $california;
    }
    .ms-content-block__text {
      font-size: 48px;
      font-weight: bold;
      line-height: 1.2;
      margin-top: 0;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 32px;
      }
    }
  }
}

.our-story-transparency {
  display: block !important;
  margin: 32px 0;
  .ms-content-block__image {
    display: none;
  }
  &.ms-content-block[data-m-layout="left-right"] {
    .ms-content-block__cta {
      .msc-cta__primary {
        @include primary-btn();
        background: transparent;
        color: $california;
        border: 1px solid $california;
        font-weight: 600 !important;
        &:hover {
          background: $california ;
          color: $msv-white;
        }
      }
    }
    .ms-content-block__title {
      color: $california;
      font-size: 48px;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 32px;
      }
    }
    
    .ms-content-block__text {
      font-size: 32px;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
      }
    }
  }
}

.our-story-tiles {
  div[class^="col"] {
    padding: 0 10px;
  }
  .ms-content-block[data-m-layout="full-width-with-container"] {
    .ms-content-block__details  {
      padding: 10px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      .msc-cta__primary {
        @include primary-btn();
        background: transparent;
        color: $msv-white;
        border: 1px solid $msv-white;
        font-weight: 600 !important;
        padding: 12px;
        &:hover,
        &:active,
        &:focus {
          background: $msv-white ;
          color: $california;
        }
      }
    }
    .ms-content-block__title {
      color: $msv-white;
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      @media (min-width: $msv-breakpoint-m) {
        font-size: 32px;
        line-height: 1.2;
      }
    }
  }
}


.white-bg {
  background: $msv-white !important;
  #main {
    background: $msv-white !important;
  }
}