.contact-us {
    padding-top: 36px;
    margin-bottom: 120px;
    @media (max-width: $msv-breakpoint-m) {
        margin-bottom: 36px;
    }
    .heading {
        width: 100%;
        text-align: center;
        background-color: $msv-white;
        font-weight: normal;
        box-shadow: 0px 0px 20px 0px $grey-light;
        font-size: 32px;
        padding: 20px 0px;
        max-width: 820px;
        margin: 0 auto 24px;
        text-transform: uppercase;
        @media (max-width: $msv-breakpoint-m) {
            font-size: 24px;
        }
    }
    .ms-text-block {
        max-width: 820px;
        margin: 0 auto;
        padding: 50px 100px;
        background-color: $msv-white;
        box-shadow: 0 2rem 4rem -2rem rgba(0, 0, 0, 0.5);
        @media (max-width: $msv-breakpoint-m) {
            padding: 32px 24px;
        }
    }
    p {
        margin-bottom: 16px;
        font-weight: 500;
    }
    a {
        text-decoration: none;
        color: $california;
        &:hover {
            text-decoration: underline;
        }
    }
}