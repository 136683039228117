$msv-account-profile-toggle-button-icon-size: 36px;
$msv-account-profile-toggle-button-padding: 0 5px;
$msv-account-profile-toggle-color: $msv-blue;
$msv-account-profile-links-padding: 6px 12px 6px 0;
$msv-account-profile-heading-padding-bottom: 24px;
$msv-account-profile-heading-margin-bottom: 44px;
$msv-account-profile-section-margin-bottom: 48px;
$msv-account-profile-section-heading-margin-bottom: 16px;
$msv-account-profile-section-preferences-margin-top: 41px;
$msv-account-profile-section-preferences-heading-margin-bottom: 38px;
$msv-account-profile-section-preferences-heading-padding-bottom: 22px;
$msv-account-profile-section-preferences-toggle-margin-top: 11px;
$msv-account-profile-section-preferences-web-tracking-margin-top: 48px;
$msv-account-profile-attributes-section-margin-bottom: 44px;
$msv-account-profile-attributes-section-save-button-margin-right: 12px;
$msv-account-profile-margin-right: 53px;
$msv-account-profile-cancel-button-margin-top: 15px;
$msv-account-profile-description-margin-bottom: 30px;
$msv-account-profile-vat-margin-bottom: 30px;

// style presets
:root {
    // title
    --msv-account-landing-tile-heading-color: var(--msv-font-primary-color);
}

.ms-account-profile {
    display: inline-flex;
    width: $msv-width-full;

    @media screen and (min-width: $msv-breakpoint-m) {
        display: inline-flex;
    }

    .ms-account-profile-wrapper {
        margin-right: $msv-account-profile-margin-right;
        width: calc(5 * #{$msv-column-width-s});

        @media screen and (max-width: $msv-breakpoint-m) {
            padding-bottom: 40px;
        }

        .ms-account-loyalty-tile__heading {
            @include font-heading-h3-l();
            padding-bottom: $msv-account-profile-heading-padding-bottom;
            border-bottom: 0.5px solid $msv-gray-50;
            color: var(--msv-account-landing-tile-heading-color);
            margin-bottom: $msv-account-profile-heading-margin-bottom;
        }

        .ms-account-profile__section {
            margin-bottom: 16px;

            &-heading {
                @include font-heading-h5-l();
                margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                padding-top: 0 !important;
            }

            &-description {
                @include font-body-regular-m();
                margin-bottom: 16px;
            }

            &-preferences {
                margin-top: 16px;

                .ms-account-profile__section-heading {
                    padding-bottom: 16px;
                    border-bottom: 0.5px solid $msv-gray-50;
                    margin-bottom: 24px;
                    padding-top: 16px !important;
                }

                .ms-account-profile__preference {
                    &-heading {
                        @include font-heading-h5-l();
                        margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                        font-size: 18px;
                    }

                    &-description {
                        @include font-body-regular-m();
                    }
                }

                .ms-account-profile__preference-web-tracking {
                    margin-top: $msv-account-profile-section-preferences-web-tracking-margin-top;
                }
            }

            &-links {
                .ms-account-profile__section-link {
                    border: 0;
                    background: transparent;
                    text-decoration: underline;
                    font-size: 14px;
                    cursor: pointer;
                    padding-left: 0;
                    &:hover {
                        text-decoration: none
                    }
                }
            }
        }
    }

    .ms-account-profile__attributes-wrapper {
        .ms-account-profile__attributes {
            &-tile__heading {
                padding-bottom: 16px;
                border-bottom: 0.5px solid $msv-gray-50;
                margin-bottom: 24px;
                padding-top: 16px !important;
            }

            &__section {
                margin-bottom: $msv-account-profile-attributes-section-margin-bottom;

                &-heading {
                    @include font-heading-h5-l();
                    margin-bottom: $msv-account-profile-section-heading-margin-bottom;
                    font-size: 18px;
                }

                &-description {
                    @include font-body-regular-m();
                }
            }

            &-edit-button {
                @include secondary-button-light();
                cursor: pointer;
                margin-right: 16px;
                padding: 12px 18px;
                height: 36px;
                min-width: 100px;
            }

            &_input-edit-false,
            &_select-edit-false {
                background: transparent;
                border: none;
                outline: none;
                color: $msv-gray-20;

                @include font-body-regular-m();
            }

            &_input-edit-true,
            &_select-edit-true {
                @include form-input-box();
            }

            &_select-edit-false {
                padding: 0;
            }

            &-save-button {
                @include primary-button-light();
                margin-right: $msv-account-profile-attributes-section-save-button-margin-right;

                @media screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
            }

            &-cancel-button {
                @include secondary-button-light();

                @media screen and (max-width: $msv-breakpoint-l) {
                    width: 100%;
                }
                margin-top: $msv-account-profile-cancel-button-margin-top;
            }
        }
    }

    @media (max-width: $msv-breakpoint-m) {
        display: block;

        .ms-account-profile-wrapper,
        .ms-account-profile__attributes-wrapper {
            width: $msv-width-full;
        }
    }
    .view-info-btn {
        @include primary-btn();
        padding: 12px 18px;
        height: 36px;
        float: right;
        margin-top: 12px;
        // @media (max-width: $msv-breakpoint-l) {
        //     float: none;
        //     margin-bottom: 16px;
        //     width: 100%;
        // }
    }
    .ms-account-profile__section-email,
    .ms-account-profile__section-name {
        display: flex;
        flex-wrap: wrap;
        .ms-account-profile__section-heading {
            font-size: 16px !important;
            margin: 0 8px 0 0;
            font-weight: 700;
        }
        .ms-account-profile__section-description {
            margin: 0;
        }
        .ms-account-profile__section-links {
            width: 100%;
            margin-top: 24px;
            margin-bottom: 8px;
        }
    }

    .editable-info {
        .ms-account-profile__section-heading {
            padding-bottom: 16px;
            border-bottom: 0.5px solid $msv-gray-50;
            margin-bottom: 24px;
            padding-top: 16px !important;
        }
        .showmore-btn {
            @include primary-btn();
            padding: 12px 18px;
            height: 36px;
            margin-bottom: 16px;
        }
        .edit-btn {
            border: 0;
            background: transparent;
            text-decoration: underline;
            font-size: 14px;
            cursor: pointer;
            padding-left: 0;
            &:hover {
                text-decoration: none
            }
        }
        .save-btn {
            @include primary-btn();
            cursor: pointer;
            margin-right: 16px;
            padding: 12px 18px;
            height: 36px;
            min-width: 100px;
            &.is-busy {
                &::before {
                    @include msv-icon();
                    content: $msv-Spinner;
                    margin: 0;
                    -webkit-animation: spin 1s steps(8) infinite;
                    animation: spin 1s steps(8) infinite;
                    position: absolute;
                    left: 16px;
                }
    
                cursor: progress;
            }
        }
        .form-group {
            margin-bottom: 8px;
            flex-direction: column;
            width: 50%;
            display: inline-flex;
            @media (max-width: $msv-breakpoint-m) {
                width: 100%;
            }
            label {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                margin-bottom: 8px;
                margin-top: 8px;
            }
            input,
            select {
                height: 40px;
                border: 1px solid $msv-gray-50;
                padding: 8px;
                width: 100%;
            }
            .disabled-inp {
                height: 40px;
                border: 1px solid $msv-gray-50;
                padding: 8px;
                width: 100%;
                opacity: 0.7;
                cursor: not-allowed;
            }
            // &:nth-child(odd) {
            //     padding-right: 16px;
            //     @media(max-width: $msv-breakpoint-m) {
            //         padding-right: 0;
            //     }
            // }
        }
        .account-profile-form {
            display: flex;
            flex-wrap: wrap;
            .form-group {
                padding-right: 16px;
            }
        }
        ul {
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            li {
                padding: 8px 0;
                width: 50%;
                @media (max-width: $msv-breakpoint-m) {
                    width: 100%;
                }
                span {
                    &:first-child {
                        font-weight: 700;
                        margin-right: 8px;
                    }
                }
            }
            &.edit-mode {
                li {
                    flex-direction: column;
                    display: flex;
                    padding-right: 16px;
                    span {
                        &:first-child {
                            margin-bottom: 8px;
                        }
                        &:last-child {
                            padding-right: 8px;
                            height: 40px;
                            border: 1px solid $msv-gray-50;
                            padding: 8px;
                            cursor: not-allowed;
                            opacity: 0.7;
                        }
                    }
                }
            }
        }
        .form-btns {
            margin-bottom: 24px;
            margin-top: 16px;
            .cancel {
                @include secondary-button-light();
                cursor: pointer;
                margin-right: 16px;
                padding: 12px 18px;
                height: 36px;
                min-width: 100px;
                text-decoration: none;
                &:focus {
                    background: $msv-white;
                    color: inherit;
                }
            }
        }
        .PersonalTitle {
            order: -5;
        }
        .Name {
            order: -4;
        }
        .Email {
            order: -3;
        }
        .DateOfBirth {
            order: -2;
        }
        .PersonProfessionalTitle {
            order: -1;
        }
    }

}

.ms-account-profile,
.ms-account-profile .ms-account-profile__attributes-wrapper .ms-account-profile__attributes__section {
    &__toggle-wrapper {
        margin-top: $msv-account-profile-section-preferences-toggle-margin-top;

        @include font-body-regular-m();
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    &__toggle-enable {
        .ms-account-profile__toggle-enable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-disable {
        .ms-account-profile__toggle-disable-text {
            font-weight: var(--msv-font-weight-bold);
        }
    }

    &__toggle-enable-button {
        @include toggle-button-on();
    }

    &__toggle-disable-button {
        @include toggle-button-off();
    }
}

.ms-account-profile
    .ms-account-profile__attributes-wrapper
    .ms-account-profile__attributes__section.ms-account-profile__attributes-VAT_Number {
    margin-bottom: $msv-account-profile-vat-margin-bottom;
}

.my-profile {
    .row {
        background-color: $msv-white !important;
        box-shadow: 0px 0px 10px 0px $concrete !important;
        border: none !important;
        border-radius: 0px !important;
        margin-bottom: 25px !important;
        padding: 20px !important;
    }
    h2 {
        padding-top: 20px !important;
        font-size: 20px !important;
    }
    .ms-account-organization-users-tile,
    .ms-account-order-templates-tile,
    .ms-account-invoice-tile {
        margin-top: 0px !important;
    }
    .ms-account-address-tile {
        margin-top: 0px !important;
    }
    .breadcrumb {
        background: transparent;
    }
}

.my-profile1 {
    margin: auto !important;
    margin-bottom: 20px !important;
    .ms-breadcrumb {
        display: none !important;
    }
    h2 {
        margin-bottom: 0px !important;
        border: none !important;
        background-color: $msv-white;
        font-weight: initial;
        box-shadow: 0px 0px 20px 0px $grey-light !important;
        font-size: 38px;
        padding: 20px 0px !important;
        text-align: center;
    }
}

// .organization-fields-modal {
//     &.msc-modal__dialog {
//         @media (min-width: $msv-breakpoint-m) {
//             max-width: 490px;
//         }
//     }
//     .msc-modal__content {
//         padding: 0;
//         @media (max-width: $msv-breakpoint-m) {
//             padding: 0 !important;
//         }
//     }
//     .msc-modal__title {
//         width: 100%;
//     }
//     .msc-modal__header {
//         border-bottom: 1px solid $msv-gray-50;
//         margin: 16px 0;
//         padding: 0 24px 0 32px;
//     }
//     .heading-wraper {
//         display: flex;
//         justify-content: space-between;
//         h1 {
//             font-size: 28px;
//             @media (max-width: $msv-breakpoint-m) {
//                 font-size: 24px;
//             }
//         }
//     }
//     .close-btn {
//         background: none;
//         border: 0;
//         @include add-icon($msv-Cancel, before);
//     }
//     ul {
//         padding: 0;
//         margin: 0;
//         display: flex;
//         flex-wrap: wrap;
//         justify-content: space-between;
//         list-style-type: none;
//         li {
//             display: flex;
//             padding: 8px 32px;
//             border-bottom: 1px solid $msv-gray-930;
//             font-weight: 500;
//             width: 100%;
//             &:nth-child(odd) {
//                 background: rgba($msv-gray-930, 0.6);
//             }
//             @media (max-width: $msv-breakpoint-m) {
//                 flex-wrap: wrap;
//             }
//             span {
//                 width: 50%;
//                 @media (max-width: $msv-breakpoint-m) {
//                     width: 100%;
//                 }
//                 &:first-child {
//                     font-weight: 700;
//                     margin-right: 5px;
//                     @media (max-width: $msv-breakpoint-m) {
//                         margin-bottom: 8px;
//                     }
//                 }
//             }
//         }
//     }
// }
