;
@import '../../../../node_modules/react-datepicker/src/stylesheets/datepicker.scss';
@import 'bootstrap/scss/bootstrap';
@import '00-settings/index';
@import '01-tools/index';
@import '02-generic/index';
@import '03-components/index';
@import '04-modules/index';
@import '05-rtl/index';
@import './components/waiting.scss';
@import '06-css-override/override';
@import 'react-material-stepper/src/index.scss';

$brandPrimaryColor: #ffffff;
$brandSecondaryColor: #cccccc;
$backgroundDefaultColor: #ffffff;
$textDefaultColor: #333333;
$bodyFont: Arial;
$displayFont: Impact;
$borderRadius: 4;
$iconStyle: Line;
$categoryColor: #000;
$expandedCategoryColor: #000;

:root {
    --brandPrimaryColor: #{brandPrimaryColor};
    --brandSecondaryColor: #{brandSecondaryColor};
    --backgroundDefaultColor: #{$backgroundDefaultColor};
    --textDefaultColor: #{textDefaultColor};
    --bodyFont: #{bodyFont};
    --displayFont: #{displayFont};
    --borderRadius: #{borderRadius};
    --iconStyle: #{iconStyle};
    --categoryColor: #{categoryColor};
    --expandedCategoryColor: #{expandedCategoryColor};
}

body {
    background-color: var(--backgroundDefaultColor);
}
body,
a,
p,
div,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
input,
label,
select {
    font-family: $msv-primary-font-family !important;
}
body {
    outline: 0 !important;
}
a,
a:active,
a:focus {
    outline: 0 !important;
    -moz-outline-style: none !important;
}
button:hover,
button:focus,
button span,
input {
    outline: 0 !important;
}
