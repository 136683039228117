$msv-business-organization-list-width: 100%;
$msv-business-organization-list-heading-margin-top: 20px;
$msv-business-organization-list-border-radius: 2px;
$msv-business-organization-list-form-item-margin: 20px 0 0;
$msv-business-organization-list-form-item-margin-right: 10px;
$msv-business-organization-list-input-border: 1px solid transparent;
$msv-business-organization-list-input-size: 52px;
$msv-business-organization-list-input-padding: 14px 12px;
$msv-business-organization-list-input-spacing: 20px;
$msv-business-organization-list-label-margin: 0 0 5px 0;
$msv-business-organization-list-error-bg-color: $msv-error-color;
$msv-business-organization-list-list-item-spacing: 8px;
$msv-business-organization-list-table-margin-top: 20px;
$msv-business-organization-list-table-data-row-height: 82px;
$msv-business-organization-list-modal-padding: 2 0;
$msv-business-organization-list-pagination-padding-left: 0;
$msv-business-organization-list-page-item-page-link-padding: 8px 4px;
$msv-business-organization-list-page-prev-next-padding: 0 4px;
$msv-business-organization-list-icon-font-size: 20px;
$msv-business-organization-list-rowlinks-view-margin-right: 12px;
$msv-business-organization-list-modal-typedetails-margin-right: 20px;
$msv-business-organization-list-modal-typedetails-margin-left: 20px;
$msv-business-organization-list-modal-heading-margin-top: 32px;
$msv-business-organization-list-page-item-cancel-margin: 0 20px;
$msv-business-organization-list-modal-heading-margin-bottom: 40px;
$msv-business-organization-list-add-user-button-margin: 20px 0;
$msv-business-organization-list-add-user-button-padding: 12px 8px;
$msv-business-organization-list-padRight-padding-right: 40px;
$msv-business-organization-list-padding: 36px;
$msv-business-organization-list-modal-view-padding: 0 8px;
$msv-business-organization-list-table-padding-top: 105px;
$msv-business-organization-list-table-row-links-padding: 20px;
$msv-business-organization-list-toggle-up-margin-right: 20px;
$msv-business-organization-list-toggle-up-margin-left: 20px;
$msv-business-organization-list-table-row-height: 42px;
$msv-business-organization-list-table-sorting-height: 42px;
$msv-business-organization-list-request-statement-date-width: 300px;
$msv-business-organization-list-request-statement-date-padding-top: 20px;
$msv-business-organization-list-request-statement-toggle-min-width: 160px;
$msv-business-organization-list-request-statement-toggle-min-height: 40px;
$msv-business-organization-list-request-statement-height: 44px;
$msv-business-organization-list-request-statement-border-radius: 100px;
$msv-business-organization-list-request-statement-margin-top: 48px;
$popover-menu-item-height: 48px;
$msv-business-organization-list-table-border: 1px solid $msv-gray-300;
$msv-business-organization-list-action-link-padding: 0 10px;
$msv-business-organization-list-action-link-margin-right: 53px;
$ms-business-organization-list-modal-body-padding: 36px;
$ms-business-organization-list-modal-body-margin-bottom: 15px;
$ms-business-organization-list-form-margin-top: 20px;

$msv-business-organization-list-container-no-users-margin-top: 40px;
$msv-business-organization-list-container-no-users-heading-margin-top: 80px;
$msv-business-organization-list-container-no-users-heading-max-width: 650px;
$msv-business-organization-list-container-no-users-heading-margin-top-t: 67px;
$msv-business-organization-list-container-no-users-heading-max-width-t: 348px;
$msv-business-organization-list-container-no-users-heading-margin-top-m: 48px;
$msv-business-organization-list-container-no-users-text-margin-top: 12px;
$msv-business-organization-list-container-no-users-text-max-width: 413px;
$msv-business-organization-list-container-no-users-text-margin-top-t: 59px;
$msv-business-organization-list-container-no-users-text-margin-top-m: 32px;
$msv-business-organization-list-container-no-users-text-max-width-t: 450px;
$msv-business-organization-list-container-no-users-button-bar-margin-bottom: 85px;
$msv-business-organization-list-container-no-users-button-margin-top: 38px;
$msv-business-organization-list-container-no-users-button-bar-margin-bottom-t: 72px;
$msv-business-organization-list-container-no-users-button-margin-top-t: 51px;
$msv-business-organization-list-container-no-users-button-bar-margin-bottom-m: 120px;
$msv-business-organization-list-container-no-users-button-margin-top-m: 58px;
$msv-business-organization-list-dropdown-border-radius: 10px;
$msv-business-organization-list-dropdown-toggle-down-padding: 12px 5px;
$msv-business-organization-list-sendToEmail-padding-top: 5px;
$msv-business-organization-list-sendToEmail-padding-bottom: 20px;
$msv-business-organization-list-todate-input-padding-left: 10px;
$msv-business-organization-list-todate-input-padding-right: 10px;
$msv-business-organization-list-fromdate-input-padding-left: 10px;
$msv-business-organization-list-fromdate-input-padding-right: 10px;
$msv-business-organization-list-fromdate-todate-input-border-radius: 3px;
$msv-business-organization-list-dateSelector-margin-top: 5px;
$msv-business-organization-list-request-statement-padding: 10px 21px 10px 24px;
$msv-business-organization-list-request-statement-padding-left: 15px;
$msv-business-organization-list-request-statement-padding-right: 23px;
$msv-business-organization-list-request-statement-button-label-position-top: -6px;
$msv-business-organization-list-request-statement-icon-margin-left: 20px;

.ms-business-organization-list {
    padding: 0;

    &__container {
        width: $msv-business-organization-list-width;
    }

    &__heading {
        @include font-heading-h3-l();
        margin-top: $msv-business-organization-list-heading-margin-top;

        @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l) {
            @include font-heading-h3-m();
        }

        @media (max-width: $msv-breakpoint-m) {
            @include font-heading-h3-s();
        }
    }

    &__add-user-button {
        @include primary-button-light();
        margin: $msv-business-organization-list-add-user-button-margin;
        margin-top: 48px;
        width: 203px;
    }

    &__dateSelector {
        @include font-body-regular-s();
        margin-top: $msv-business-organization-list-dateSelector-margin-top;
        display: block;
        width: 95%;
    }

    &__sendToEmail {
        @include font-body-regular-m();
        padding-top: $msv-business-organization-list-sendToEmail-padding-top;
        padding-bottom: $msv-business-organization-list-sendToEmail-padding-bottom;
    }

    &__action {
        &__bar {
            margin-top: 10px;
        }

        &__requestStatement {
            border: 1px solid var(--msv-accent-brand-color);
            background-color: var(--msv-bg-color);
            padding: 10px;
            width: 100%;
            text-align: center;

            &__toggleDown {
                border: 1px solid var(--msv-accent-brand-color);
                background-color: var(--msv-bg-color);
                text-align: center;
                display: flex;
                min-width: $msv-business-organization-list-request-statement-toggle-min-width;
                margin-left: $msv-business-organization-list-toggle-up-margin-left;
                margin-top: $msv-business-organization-list-request-statement-margin-top;
                border-radius: $msv-business-organization-list-request-statement-border-radius;
                margin-right: $msv-business-organization-list-toggle-up-margin-right;
                min-height: $msv-business-organization-list-request-statement-toggle-min-height;
                padding: $msv-business-organization-list-request-statement-padding;
                height: $msv-business-organization-list-request-statement-height;
                justify-content: center;

                span {
                    @include add-icon($msv-ChevronDown, after);

                    &::after {
                        display: inline;
                    }
                }

                @media screen and (max-width: $msv-breakpoint-m - 1) {
                    margin-left: 0;
                    margin-top: 0;
                    padding: $msv-business-organization-list-dropdown-toggle-down-padding;
                    width: 100%;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin-left: $msv-business-organization-list-toggle-up-margin-left;
                    margin-top: $msv-business-organization-list-request-statement-margin-top;
                }
            }

            &__toggleUp {
                border: 1px solid var(--msv-accent-brand-color);
                background-color: var(--msv-bg-color);
                text-align: center;
                display: flex;
                min-width: $msv-business-organization-list-request-statement-toggle-min-width;
                margin-left: $msv-business-organization-list-toggle-up-margin-left;
                margin-top: $msv-business-organization-list-request-statement-margin-top;
                border-radius: $msv-business-organization-list-request-statement-border-radius;
                margin-right: $msv-business-organization-list-toggle-up-margin-right;
                padding: $msv-business-organization-list-request-statement-padding;
                min-height: $msv-business-organization-list-request-statement-toggle-min-height;
                height: $msv-business-organization-list-request-statement-height;
                justify-content: center;

                span {
                    @include add-icon($msv-ChevronUp, after);

                    &::after {
                        display: inline;
                    }
                }

                @media screen and (max-width: $msv-breakpoint-m) {
                    margin-left: 0;
                    margin-top: 0;
                }

                @media screen and (max-width: $msv-breakpoint-m - 1) {
                    width: 100%;
                    padding: $msv-business-organization-list-dropdown-toggle-down-padding;
                }

                @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
                    margin-left: $msv-business-organization-list-toggle-up-margin-left;
                    margin-top: $msv-business-organization-list-request-statement-margin-top;
                }
            }

            &__dropdownHidden {
                visibility: hidden;
                height: 0;
                display: none;
            }

            &__dropdownVisible {
                background-color: $msv-white;
                background-clip: padding-box;
                display: block;
                left: 0;
                position: absolute;
                /* stylelint-disable-next-line declaration-no-important -- To override inline styling. */
                top: 8px !important;
                word-wrap: break-word;
                z-index: 1060;
                border-radius: $msv-business-organization-list-dropdown-border-radius;
                box-shadow: $msv-depth4;

                .msc-popover-inner {
                    background-color: $msv-white;
                    background-clip: padding-box;
                    display: block;
                    left: 0;
                    top: 0;
                    word-wrap: break-word;
                    z-index: 1060;
                    box-shadow: $msv-depth4;
                    border-radius: $msv-business-organization-list-dropdown-border-radius;
                }

                .msc-btn {
                    font-weight: var(--msv-font-weight-normal);
                    height: $msv-business-organization-list-request-statement-height;
                    border: none;
                    padding: $msv-business-organization-list-request-statement-padding;
                    background: transparent;
                }

                .msc-arrow {
                    display: none;
                    height: 0.5rem;
                    margin: 0 0.3rem;
                    position: absolute;
                    top: calc((0.5rem + 1px) * -1);
                    width: 1rem;

                    &::before,
                    &::after {
                        border-color: transparent;
                        border-style: solid;
                        border-width: 0 0.5rem 0.5rem 0.5rem;
                        content: "";
                        display: block;
                        position: absolute;
                    }

                    &::before {
                        border-bottom-color: $msv-gray-500;
                        top: 0;
                    }

                    &::after {
                        border-bottom-color: var(--msv-border-color);
                        top: 1px;
                    }
                }
            }

            &__dropdownOption {
                background-color: var(--msv-bg-color);
                display: block;
                width: 100%;

                @include font-body-regular-s();
                padding-left: $msv-business-organization-list-request-statement-padding-left;
                padding-right: $msv-business-organization-list-request-statement-padding-right;

                &:hover {
                    background: $msv-white-20;
                }
            }

            &__buttonLabel {
                text-transform: uppercase;

                @include font-body-regular-s();
                line-height: 20px;
                display: inline;
                letter-spacing: 0.1em;
                position: relative;
                top: -3px;
                @media (max-width: $msv-breakpoint-m) {
                    top: -6px;
                }
                &::after {
                    font-size: $msv-business-organization-list-icon-font-size;
                    margin-left: $msv-business-organization-list-request-statement-icon-margin-left;
                    position: relative;
                    top: 6px;
                }
            }
        }

        &__submitRequest {
            @include primary-button-light();
        }

        &__cancelRequest {
            @include secondary-button-light();
            margin-left: 20px;
        }
    }

    &__requestStatement__fromDate {
        padding-top: $msv-business-organization-list-request-statement-date-padding-top;
        width: $msv-business-organization-list-request-statement-date-width;
        float: left;
        background-clip: padding-box;

        @include font-body-bold-s();

        input {
            padding-left: $msv-business-organization-list-fromdate-input-padding-left;
            padding-right: $msv-business-organization-list-fromdate-input-padding-right;
            height: 52px;
            border-radius: $msv-business-organization-list-fromdate-todate-input-border-radius;
        }
    }

    &__requestStatement__toDate {
        padding-top: $msv-business-organization-list-request-statement-date-padding-top;
        width: $msv-business-organization-list-request-statement-date-width;
        float: right;
        background-clip: padding-box;

        @media screen and (max-width: $msv-breakpoint-m) {
            float: left;
        }

        @include font-body-bold-s();

        input {
            padding-left: $msv-business-organization-list-todate-input-padding-left;
            padding-right: $msv-business-organization-list-todate-input-padding-right;
            height: 52px;
            border-radius: $msv-business-organization-list-fromdate-todate-input-border-radius;
        }
    }

    @media screen and (max-width: $msv-breakpoint-m) {
        &__requestStatement__toDate,&__requestStatement__fromDate {
            width: 100%;
        }
    }

    @media screen and (min-width: $msv-breakpoint-m) {
        &__requestStatement__toDate,&__requestStatement__fromDate {
            width: 50%;
        }
    }

    &__buttonBar {
        display: flex;

        @media screen and (max-width: $msv-breakpoint-m) {
            flex-wrap: wrap;
        }

        @media (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            display: flex;
        }
    }

    &__table {
        border-collapse: collapse;
        margin-top: $msv-business-organization-list-table-margin-top;
        table-layout: auto;
        width: 100%;

        .ms-table {
            &__row,
            &__row-data,
            &__row-links {
                @include font-body-regular-s();
                border-bottom: $msv-business-organization-list-table-border;
                height: $msv-business-organization-list-table-data-row-height;
                overflow: hidden;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;

                &.num-type {
                    text-align: left;
                }

                &.action-links {
                    padding-left: $msv-business-organization-list-table-row-links-padding;
                }
            }

            &__heading-row,
            &__heading-row-data {
                @include font-body-bold-xs();
                text-align: left;
                border-top: $msv-business-organization-list-table-border;
                border-bottom: $msv-business-organization-list-table-border;

                &__checkbox {
                    width: 60px;
                }

                &.action-links {
                    padding-top: 9px;
                    padding-bottom: 14px;
                }
            }

            th.ms-table__heading-row-data {
                height: 43px;
            }

            &__heading-sorting {
                cursor: pointer;
                padding-top: 9px;
                padding-bottom: 14px;

                &.asc {
                    @include add-icon($msv-ChevronDown, after);
                }

                &.dsc {
                    @include add-icon($msv-ChevronUp, after);
                }

                &::after {
                    color: $msv-gray-500;
                    padding-left: 8px;
                }
            }

            &__row-links {
                align-items: center;
                border-bottom: none;
                display: flex;
                padding-left: $msv-business-organization-list-table-row-links-padding;

                &-edit,
                &-delete,
                &-view {
                    margin-right: $msv-business-organization-list-action-link-margin-right;
                    background: none;
                    padding: $msv-business-organization-list-action-link-padding;
                    border: none;
                    font-size: $msv-icon-size-m;

                    &::before {
                        color: var(--msv-accent-brand-color);
                    }
                }

                &-edit {
                    @include add-icon($msv-Edit);
                }

                &-delete {
                    @include add-icon($msv-Cancel);
                }

                &-view {
                    @include add-icon($msv-List);
                }
            }
        }
    }

    &__modal {
        &-heading {
            @include font-heading-h3-l();
            margin-bottom: $msv-business-organization-list-modal-heading-margin-bottom;

            @media (min-width: $msv-breakpoint-m+1) and (max-width: $msv-breakpoint-l) {
                @include font-heading-h3-m();
            }

            @media (max-width: $msv-breakpoint-m) {
                @include font-heading-h3-s();
            }
        }

        &-body {
            @include font-body-regular-m();
            display: flex;
            flex-wrap: wrap;
            padding-left: $msv-business-organization-list-padding;
            margin: 0;
            border-top: 0.5px solid $msv-gray-50;
        }

        .msc-modal {
            &__content {
                background-color: $msv-white;
                padding: 0;
            }

            &__dialog {
                align-items: center;
                display: flex;
            }
        }

        @media (min-width: $msv-breakpoint-m) {
            .msc-modal {
                &__dialog {
                    height: auto;
                }
            }
        }

        @media (max-width: $msv-breakpoint-m) {
            .msc-modal {
                &__dialog {
                    height: 100%;
                }
            }
        }

        .msc-modal__header,
        .msc-modal__footer {
            padding: $msv-business-organization-list-padding;
        }

        .msc-modal__header {
            margin-bottom: 0;
            border-bottom: $msv-business-organization-list-table-border;
            padding-bottom: 19px;

            .msc-modal__title {
                @include font-heading-h3-l();
            }
        }

        .msc-modal__body {
            padding-right: $ms-business-organization-list-modal-body-padding;
            padding-left: $ms-business-organization-list-modal-body-padding;
            margin-bottom: $ms-business-organization-list-modal-body-margin-bottom;
        }

        ul.ms-business-organization-list__form-list {
            list-style: none;
            margin-top: 20px;
        }
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        margin-top: $ms-business-organization-list-form-margin-top;

        &-all-required-msg {
            @include font-body-regular-s();
            flex-basis: 100%;
            padding-top: 10px;
        }

        &-description {
            @include font-body-regular-m();
            margin-top: 20px;
        }

        &-list-item {
            @include font-body-regular-m();
            margin-top: 8px;

            &-name {
                @include font-body-bold-m();
            }

            span:nth-child(2) {
                margin-left: $msv-business-organization-list-list-item-spacing;
            }
        }

        &-item {
            display: flex;
            flex-direction: column;
            margin: $msv-business-organization-list-form-item-margin;

            &.width-50 {
                width: calc(50% - #{$msv-business-organization-list-input-spacing});
            }

            &.width-100 {
                width: calc(100% - #{$msv-business-organization-list-input-spacing});
            }

            &.FirstName {
                margin-right: $msv-business-organization-list-input-spacing;
            }

            &-label {
                @include font-body-bold-s();
                margin: $msv-business-organization-list-label-margin;
            }

            &-input {
                @include font-body-regular-s();
                @include vfi();
                background-color: $msv-gray-100;
                border: $msv-business-organization-list-input-border;
                border-radius: $msv-business-organization-list-border-radius;
                box-sizing: border-box;
                display: block;
                height: $msv-business-organization-list-input-size;
                padding: $msv-business-organization-list-input-padding;
            }
        }

        &-save,
        &-remove-user-button {
            @include primary-button-light();
        }

        &-cancel {
            @include secondary-button-light();
            margin: $msv-business-organization-list-page-item-cancel-margin;
        }

        @media only screen and (max-width: $msv-breakpoint-l) {
            .msc-modal__content {
                padding: $msv-business-organization-list-modal-padding;
            }

            &__form-item {
                margin-right: $msv-business-organization-list-form-item-margin-right;

                &.width-50,
                &.width-100 {
                    width: 100%;
                }
            }

            &__save-button,
            &__cancel-button {
                width: calc(50% - #{$msv-business-organization-list-input-spacing});
            }
        }

        @media only screen and (max-width: $msv-breakpoint-m) {
            &-all-required-msg {
                border-top: none;
                padding-top: 10px;
            }
        }
    }

    .ms-table-business-org__pagination {
        padding-top: $msv-business-organization-list-table-padding-top;
        width: 100%;

        .msc-pagination {
            @include font-body-regular-m();
            display: flex;
            list-style: none;
            padding-left: $msv-business-organization-list-pagination-padding-left;
            width: 100%;
        }

        ul.msc-pagination {
            display: flex;
            justify-content: center;

            .previous.msc-page-item .msc-page-link .msc-pagination__prev {
                display: inline-flex;

                .prev-text {
                    display: block;
                    margin: $msv-business-organization-list-page-prev-next-padding;
                }
            }

            .next.msc-page-item .msc-page-link .msc-pagination__next {
                display: inline-flex;

                .next-text {
                    display: block;
                    margin: $msv-business-organization-list-page-prev-next-padding;
                }
            }
        }

        &-left,
        &-right {
            display: flex;
            align-items: center;
            font-size: $msv-business-organization-list-icon-font-size;
        }

        .previous {
            margin-right: auto;
            display: block;
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-pagination__prev {
                padding-left: 4px;
            }

            .msc-page-link {
                @include font-body-regular-m();
                color: var(--msv-business-organization-list-text-font-color);
                display: block;
                padding: $msv-business-organization-list-page-item-page-link-padding;

                &::before {
                    @include msv-icon();
                    content: "";
                }

                .ms-table-business-org__pagination-left {
                    @include add-icon($msv-ChevronLeft, before);
                }
            }
        }

        .msc-page-item {
            padding-right: 0;
        }

        .msc-page-item.disabled {
            .msc-page-link {
                color: $msv-secondary;
                pointer-events: none;
                cursor: auto;
            }
        }

        .next {
            margin-left: auto;
            display: block;
            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-pagination__next {
                padding-right: 4px;
            }

            /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
            .msc-page-link {
                @include font-body-regular-m();
                color: var(--msv-business-organization-list-text-font-color);
                display: block;
                padding: $msv-business-organization-list-page-item-page-link-padding;
                padding-right: 4px;

                &::after {
                    @include msv-icon();
                    content: "";
                }

                .ms-table-business-org__pagination-right {
                    @include add-icon($msv-ChevronRight, after);
                }
            }
        }

        /* stylelint-disable-next-line no-descending-specificity -- Auto-suppressed. Please fix if possible. */
        .msc-page-link {
            @include font-body-regular-m();
            display: block;
            padding: $msv-business-organization-list-page-item-page-link-padding;

            @include vfi();
        }

        .active {
            text-decoration: underline;
        }
    }

    &__container-no-users {
        margin-top: $msv-business-organization-list-container-no-users-margin-top;
        border: 0.5px solid $msv-gray-50;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        background: $msv-white;
        padding: 16px;
        .permission-denied-heading {
            text-align: center
        }

        &-heading {
            @include font-heading-h2-l();
            margin-top: $msv-business-organization-list-container-no-users-heading-margin-top;
            max-width: $msv-business-organization-list-container-no-users-heading-max-width;
            text-align: center;
        }

        &-text {
            @include font-body-regular-m();
            max-width: $msv-business-organization-list-container-no-users-text-max-width;
            margin-top: $msv-business-organization-list-container-no-users-text-margin-top;
            text-align: center;
        }

        .ms-business-organization-list__buttonBar {
            margin-bottom: $msv-business-organization-list-container-no-users-button-bar-margin-bottom;
            width: 100%;
            justify-content: center;

            button {
                margin-top: $msv-business-organization-list-container-no-users-button-margin-top;
            }
        }

        @media screen and (max-width: $msv-breakpoint-m) {
            border-color: transparent;

            &-heading {
                @include font-heading-h2-s();
                margin-top: $msv-business-organization-list-container-no-users-heading-margin-top-m;
                max-width: unset;
            }

            &-text {
                max-width: unset;
                margin-top: $msv-business-organization-list-container-no-users-text-margin-top-m;
            }
        }

        @media screen and (min-width: $msv-breakpoint-m) and (max-width: $msv-breakpoint-l) {
            &-heading {
                @include font-heading-h2-m();
                margin-top: $msv-business-organization-list-container-no-users-heading-margin-top-t;
                max-width: $msv-business-organization-list-container-no-users-heading-max-width-t;
            }

            &-text {
                max-width: $msv-business-organization-list-container-no-users-text-max-width-t;
                margin-top: $msv-business-organization-list-container-no-users-text-margin-top-t;
            }
        }
    }

    @media only screen and (max-width: $msv-breakpoint-l) {
        padding: 0;

        &__add-user-button {
            width: 50%;
            padding: $msv-business-organization-list-add-user-button-padding;
        }

        &__form {
            &-item {
                &.width-25,
                &.width-50,
                &.width-100 {
                    margin-right: 0;
                    width: 100%;
                }
            }

            &-cancel {
                background-color: $msv-white;
            }
        }

        &__heading {
            margin-top: $msv-business-organization-list-modal-heading-margin-top;
        }

        &__modal {
            &.type-details {
                margin-right: $msv-business-organization-list-modal-typedetails-margin-right;
                margin-left: $msv-business-organization-list-modal-typedetails-margin-left;
            }

            &.type-view {
                .ms-business-organization-list__form-cancel {
                    width: 100%;
                }
            }
        }

        .ms-table {
            &__heading {
                &-row,
                &-row-data {
                    height: $msv-business-organization-list-table-row-height;
                }

                &-sorting {
                    height: $msv-business-organization-list-table-sorting-height;
                }
            }

            &__row-links {
                align-items: flex-start;
                background: $msv-white;
                border-radius: 2px;
                box-shadow: $msv-depth4;
                flex-direction: column;
                height: auto;
                padding-left: 0;
                position: absolute;
                left: 0;
                width: 100%;
                z-index: 10;

                &-edit,
                &-delete,
                &-view {
                    background: none;
                    color: var(--msv-business-organization-list-actions-text-color);
                    height: 60px;
                    padding: $msv-business-organization-list-modal-view-padding;
                    text-align: left;
                    width: 100%;

                    &::before {
                        margin-right: $msv-business-organization-list-rowlinks-view-margin-right;
                        width: 16px;
                    }
                }

                &-minified {
                    text-align: right;
                }

                &-toggle {
                    @include add-icon($msv-Ellipses-V);
                    background: none;
                    height: $msv-business-organization-list-table-data-row-height;
                    border: none;
                }
            }

            &__row {
                position: relative;

                &-data {
                    font-weight: 600;
                }
            }
        }
    }

    @media screen and (max-width: $msv-breakpoint-m - 1) {
        &__add-user-button {
            width: 100%;
        }
    }

    &__padRight {
        padding-right: $msv-business-organization-list-padRight-padding-right;
    }
    &__dropdown {
        @media (max-width: $msv-breakpoint-m) {
            width: 100%;
        }
    }
}
