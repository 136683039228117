.blocked-user-page {
    #main,
    footer {
        padding: 0;
    }
    #main {
        background: $msv-white !important;
    }
    .blocked-contact {
        min-height: 100vh;
        @media(max-width: $msv-breakpoint-l) {
            margin: 0 auto;
        }
        .content-block-logo {
            max-width: 320px;
            margin: 0 auto;
        }
        &[data-m-layout="full-width"] {
            .ms-content-block__title {
                color: $nandor;
                font-size: 28px;
            }
            .ms-content-block__text {
                width: 100%;
                color: $nandor;
            }
        }
        .ms-content-block__details {
            padding: 32px 16px;
            justify-content: center;
            width: 480px;
            height: 480px;
            background: $msv-white;
            overflow: hidden;
            margin: 0 auto;
            border: 1px solid transparent;
            border-radius: 4px;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
            top: 50%;
            transform: translateY(-50%);
            @media(max-width: $msv-breakpoint-l) {
                position: static;
                width: auto;
                transform: none;
            }
        }
        .ms-content-block__image {
            @media(max-width: $msv-breakpoint-l) {
                display: none;
            }
            img {
                @media(min-width: $msv-breakpoint-l) {
                    min-height: 100vh;
                }
            }
        }
    }
}