$circular-url-path:'../../../suedwolle/circularStd/';

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-Black.eot');
    src: url('#{$circular-url-path}CircularStd-Black.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-Black.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-Black.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-Black.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-Black.svg#CircularStd-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-BlackItalic.eot');
    src: url('#{$circular-url-path}CircularStd-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-BlackItalic.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-BlackItalic.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-BlackItalic.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-BlackItalic.svg#CircularStd-BlackItalic') format('svg');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-Bold.eot');
    src: url('#{$circular-url-path}CircularStd-Bold.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-Bold.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-Bold.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-Bold.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-Bold.svg#CircularStd-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-BoldItalic.eot');
    src: url('#{$circular-url-path}CircularStd-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-BoldItalic.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-BoldItalic.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-BoldItalic.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-BoldItalic.svg#CircularStd-BoldItalic') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-Book.eot');
    src: url('#{$circular-url-path}CircularStd-Book.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-Book.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-Book.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-Book.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-Book.svg#CircularStd-Book') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-BookItalic.eot');
    src: url('#{$circular-url-path}CircularStd-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-BookItalic.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-BookItalic.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-BookItalic.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-BookItalic.svg#CircularStd-BookItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-MediumItalic.eot');
    src: url('#{$circular-url-path}CircularStd-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-MediumItalic.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-MediumItalic.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-MediumItalic.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-MediumItalic.svg#CircularStd-MediumItalic') format('svg');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Circular Std';
    src: url('#{$circular-url-path}CircularStd-Medium.eot');
    src: url('#{$circular-url-path}CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
        url('#{$circular-url-path}CircularStd-Medium.woff2') format('woff2'),
        url('#{$circular-url-path}CircularStd-Medium.woff') format('woff'),
        url('#{$circular-url-path}CircularStd-Medium.ttf') format('truetype'),
        url('#{$circular-url-path}CircularStd-Medium.svg#CircularStd-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

