.msc-add-to-order-template-icon,
.pdp-template-icon {
    @include add-icon($msv-order-template-add, before, $msv-outline-icon-weight);
    @include vfi();

    &__added {
        @include add-icon($msv-order-template-add, before);
    }

    &:not(:disabled) {
        &:hover {
            cursor: pointer;
        }
    }
}
.pdp-template-icon {
    padding-top: 0 !important;
    vertical-align: middle;
    margin-top: 5px;
    &::before {
        content: $msv-order-template-add !important;
        font-size: 20px !important;
        margin: 0 10px !important;
    }
}
.add-to-template-wrapper {
    position: relative;
    display: inline-flex;
    align-items: center;
    .msc-tooltip {
        min-width: max-content;
    }
    .msc-add-to-wishlist.msc-add-to-cart-extra-actions {
        max-width: 100%;
        min-width: 100%;
    }
    button {
        padding: 0 !important;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 0 !important;
        &::before {
            display: none !important;
        }
    }
}
