.faq-wrapper {
    background-color: $msv-white;
    padding: 20px;
    h2 {
      font-size: 38px;
      padding: 19px 0px;
      margin-bottom: 5px;
      text-align: center;
      line-height: normal;
      display: block;
      width: 100%;
      @media (max-width: $msv-breakpoint-m) {
        font-size: 24px;
      }
  }
  .ms-accordion-item-section {
    .drawer__buttontext.__start {
      font-weight: 600;
    }
    .drawer__button.msc-btn.btn-block:focus {
      border: 0;
    }
  }
  .ms-accordion-item-content {
    padding: 16px 16px 0;
    border-top: 1px solid $msv-gray-50;
  }
  .ms-accordion-header-section-toggle-button-container {
    button {
      text-transform: uppercase;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .ms-accordion-item {
    &:last-child {
      border-bottom: 1px solid $msv-gray-50;
    }
  }

  // FAQ with tabs
  .ms-tab {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    .ms-accordion-item {
      &:last-child {
        border: 0;
      }
    }
    .ms-accordion-item-section {
      .ms-text-block {
        margin: 0;
        font-weight: 500;
        a {
          color: $link;
          &:hover {
            text-decoration: none;
          }
        }
      }
      .drawer.ms-accordion-item__drawer {
        background: transparent;
        border: 0;
      }
      .drawer__button.msc-btn.btn-block {
        background: transparent;
        height: auto;
        padding: 16px 24px;
      }
    }
  }
  .ms-tab-header-section {
    width: 100%;
  }
  .ms-tab-tabs-section {
    flex-direction: column;
    width: 25%;
    height: fit-content;
    position: sticky;
    top: 0;
    select {
      border: 0;
      border-bottom: 1px solid $california;
      padding-bottom: 8px;
      font-size: 18px;
      font-weight: 500;
    }
    @media (max-width: $msv-breakpoint-m) {
      width: 100%;
      margin-bottom: 32px;
    }
  }
  .ms-tab-content-section {
    width: 75%;
    margin: 0;
    @media (max-width: $msv-breakpoint-m) {
      width: 100%;
    }
  }
  .ms-accordion {
    background-color: $concrete;
  }
  .ms-accordion-item-content {
    background: $msv-white;
    padding: 16px 24px;
  }
  .ms-tab-tabs-section-li {
    margin: 0;
    padding: 16px 24px;
    position: relative;
    @include add-icon($msv-ChevronRight);
    &::before {
      position: absolute;
      right: 16px;
    }
    &[aria-selected="true"] {
      background-color: $concrete;
      .ms-tab-item-header {
        font-weight: bold;
      }
    }
    ::before {
      display: none;
    }
  }
}
